import { useAppSelector } from '../../../store';
import { ForgotPasswordForm } from '../form';
import ResetPasswordForm from '../form/reset-password.component';

export const ForgotPasswordLayout = () => {
  const { passwordReset } = useAppSelector((state) => state.auth);

  return (
    <>
      { passwordReset ? <ResetPasswordForm /> : <ForgotPasswordForm />}
    </>
  );
};