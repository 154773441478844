import { GetLeadsRequest } from "@cleanlist-ai/cleanse-api-client-lib";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { leadsProvider } from "../api";
import { AppState } from "../store";
import { ILeadsState, LeadsErrorCodesEnum } from "./types";

const initialState: ILeadsState = {
  leads: [],
  error: null,
  loading: false,
  lastExtractedLeads: [],
};

export const fetchLeads = createAsyncThunk(
  "@@contacts/fetchLeads",
  async (payload: GetLeadsRequest = {}, { getState, rejectWithValue }) => {
    try {
      const { apiKey } = (getState() as AppState).user;
      if (!apiKey?.key) {
        throw new Error("No api key found");
      }
      return await leadsProvider.fetchLeads(apiKey.key, payload);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchLead = createAsyncThunk(
  "@@leads/fetchLead",
  async (payload: string, { getState, rejectWithValue }) => {
    try {
      const { apiKey } = (getState() as AppState).user;
      if (!apiKey?.key) {
        throw new Error("No api key found");
      }
      return await leadsProvider.fetchLead(apiKey.key, {
        publicLinkedinUrl: payload,
      });
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchLastExtractedLeads = createAsyncThunk(
  "@@leads/fetchLastExtractedLeads",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { apiKey } = (getState() as AppState).user;
      if (!apiKey?.key) {
        throw new Error("No api key found");
      }
      // TODO: Move to leads thunk
      return await leadsProvider.fetchLatestExtractedLeads(apiKey.key);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

const leadsSlice = createSlice({
  name: "@@leads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: LeadsErrorCodesEnum.FETCH_LEADS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        // Object.assign(state, action.payload);
        state.leads = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchLastExtractedLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLastExtractedLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: LeadsErrorCodesEnum.FETCH_LATEST_EXTRACTED_LEADS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchLastExtractedLeads.fulfilled, (state, action) => {
        // Object.assign(state, action.payload);
        state.lastExtractedLeads = action.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = leadsSlice.actions;

export const leadsReducer = leadsSlice.reducer;
