import { cn } from "@cleanlist-ai/portal/utils";
import { PiTextColumns } from "react-icons/pi";
import { ActionIcon, Checkbox, CheckboxGroup, Popover, Title } from "rizzui";
import { ColumnBase } from "./controlled-table/table-filter";

type ToggleColumnsTypes<T> = {
  columns: T[];
  checkedColumns: string[];
  setCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  hideIndex?: number;
};

export function ToggleTableColumns<T>({
  columns,
  checkedColumns,
  setCheckedColumns,
  hideIndex,
}: ToggleColumnsTypes<T>) {
  return (
    <div>
      <Popover shadow="sm" placement="bottom-end">
        <Popover.Trigger>
          <ActionIcon variant="outline" title={"Toggle Columns"}>
            <PiTextColumns strokeWidth={3} className=" h-6 w-6" />
          </ActionIcon>
        </Popover.Trigger>
        <Popover.Content>
          <div className="px-0.5 pt-2 text-left rtl:text-right">
            <Title as="h6" className="mb-1 px-0.5 text-sm font-semibold">
              Toggle Columns
            </Title>
            <CheckboxGroup
              values={checkedColumns}
              setValues={setCheckedColumns}
              className="grid grid-cols-2 gap-x-6 gap-y-5 px-1.5 pb-3.5 pt-4"
            >
              {columns.map((column, index) => (
                <Checkbox
                  key={(column as ColumnBase).dataIndex}
                  value={(column as ColumnBase).dataIndex}
                  label={(column as ColumnBase).key}
                  labelClassName="ml-2 rtl:mr-2 text-[13px] font-medium"
                  className={cn({ hidden: hideIndex && index === hideIndex })}
                />
              ))}
            </CheckboxGroup>
          </div>
        </Popover.Content>
      </Popover>
    </div>
  );
}
