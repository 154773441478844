import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { crmProvider } from "../api";
import { WebtokenPayload } from "../auth/types";
import { CRMErrorCodesEnum, ICRMState } from "./types";

const initialState: ICRMState = {
  options: [],
  loading: false,
  error: null,
};

export const fetchCRMOptions = createAsyncThunk(
  "@@auth/fetchCRMOptions",
  async (payload: WebtokenPayload, { rejectWithValue }) => {
    try {
      const crms = await crmProvider.fetchCRMOptions(payload.token);
      // Order based on onboarded status
      return crms.sort((a, b) =>
        a.onboarded === b.onboarded ? 1 : a.onboarded ? -1 : 0
      );
    } catch (e) {
      console.error((e as Error).message);
      return rejectWithValue("Failed to get CRM options. Please try again.");
    }
  }
);

export const crmSlice = createSlice({
  name: "@@crm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCRMOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCRMOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: CRMErrorCodesEnum.FETCH_CRM_OPTIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchCRMOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.options = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = crmSlice.actions;

export const crmReducer = crmSlice.reducer;
