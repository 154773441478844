import { ReactNode } from 'react';
import { TableRefreshButton } from '../table';
import { MenuEntry } from './global/menu';
import { Header } from './header.component';

type TableLayoutProps = {
  heading: string;
  breadcrumb: MenuEntry[];
  onClick: () => void;
  children: ReactNode;
};

export const TableLayout = ({
  heading,
  breadcrumb,
  onClick,
  children,
}: TableLayoutProps) => {
  return (
    <>
      <Header heading={heading} breadcrumb={breadcrumb}>
        <div className="mt-4 flex items-center gap-3 @lg:mt-0">
          <TableRefreshButton onClick={onClick} />
        </div>
      </Header>
      {children}
    </>
  );
};
