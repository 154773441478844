import { PHASE, useAppSelector } from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import { OnboardingFooter } from "../onboarding-footer.component";
import { Congratulations } from "./congratulations.component";
import { PhaseFive } from "./phase-five.component";
import { PhaseFour } from "./phase-four.component";
import { PhaseOne } from "./phase-one.component";
import { PhaseThree } from "./phase-three.component";
import { PhaseTwo } from "./phase-two.component";

const MAP_PHASE_TO_COMPONENT = {
  [PHASE.ONE]: PhaseOne,
  [PHASE.TWO]: PhaseTwo,
  [PHASE.THREE]: PhaseThree,
  [PHASE.FOUR]: PhaseFour,
  [PHASE.FIVE]: PhaseFive,
  [PHASE.SIX]: Congratulations,
};

export const phasesCount = Object.keys(MAP_PHASE_TO_COMPONENT).length;

export const Phases = () => {
  const { phase } = useAppSelector((state) => state.layout);
  const Component = MAP_PHASE_TO_COMPONENT[phase];
  return (
    <>
      <div
        className={cn(
          "mx-auto grid w-full max-w-screen-2xl grid-cols-12 place-content-center gap-6 px-5 py-10 @3xl:min-h-[calc(100vh-10rem)] @5xl:gap-8 @6xl:gap-16 xl:px-7"
        )}
      >
        <Component />
      </div>
      <OnboardingFooter />
    </>
  );
};
