import { portalConfig } from "@cleanlist-ai/portal/config";
import { cn } from "@cleanlist-ai/portal/utils";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Button, Title } from "rizzui";
import NotFoundPng from "../../assets/not-found.png";

export const Error = () => {
  const pathname = usePathname();

  return (
    <div className="flex min-h-screen flex-col bg-[#F8FAFC] dark:bg-gray-50">
      <div className="sticky top-0 z-40 px-6 py-5 backdrop-blur-lg lg:backdrop-blur-none xl:px-10 xl:py-8">
        <div
          className={cn(
            "mx-auto flex max-w-[1520px] items-center justify-center"
          )}
        >
          <Link href="/">
            <Image
              src={portalConfig.logo}
              alt={portalConfig.title}
              // className="dark:invert"
              priority
            />
          </Link>
        </div>
      </div>
      <div className="flex grow items-center px-6 xl:px-10">
        <div className="mx-auto text-center">
          <Image
            src={NotFoundPng}
            alt="not found"
            className="mx-auto mb-8 aspect-[360/326] max-w-[256px] xs:max-w-[370px] lg:mb-12 2xl:mb-16"
          />
          <Title
            as="h1"
            className="text-[22px] font-bold leading-normal text-gray-1000 lg:text-3xl"
          >
            Sorry - {pathname} was not found
          </Title>
          <p className="mt-3 text-sm leading-loose text-gray-500 lg:mt-6 lg:text-base lg:leading-loose">
            Reporting problems/bugs with the product can be done by register to
            Cleanlist
            <br className="hidden sm:inline-block" />
            mailing list or follow us on Linkedin for get latest update.
          </p>
          <Link href="/">
            <Button
              as="span"
              size="xl"
              color="primary"
              className="mt-8 h-12 px-4 xl:h-14 xl:px-6"
            >
              {/* <PiHouseLineBold className="mr-1.5 text-lg" /> */}
              Back to Landing
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
