import { usePhases } from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import { phasesCount } from "./phases";

type PhasesBreadcrumbProps = {
  heading: string;
  description: string;
  className?: string;
  headingClassName?: string;
  descriptionClassName?: string;
};
export const PhasesBreadcrumb = ({
  description,
  heading,
  className,
  descriptionClassName,
  headingClassName,
}: PhasesBreadcrumbProps) => {
  const { phase } = usePhases();
  return (
    <div className={cn("text-base text-white", className)}>
      <div className="flex">
        <span className="me-2 mt-2.5 h-0.5 w-11 bg-white/[.35]" /> Onboarding{" "}
        {phase + 1} of {phasesCount - 1}
      </div>
      <article className="mt-4 @3xl:mt-9">
        <h1
          className={cn(
            "text-xl text-white @3xl:text-2xl @7xl:text-3xl @[113rem]:text-4xl",
            headingClassName
          )}
        >
          {heading}
        </h1>
        <p
          className={cn(
            "mt-3 text-sm leading-relaxed @3xl:text-base",
            descriptionClassName
          )}
        >
          {description}
        </p>
      </article>
    </div>
  );
};
