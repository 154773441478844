import {
  CreateApiKeyOperationRequest,
  GetApiKeyLimitsRequest,
} from "@cleanlist-ai/cleanse-api-client-lib";
import { userApi } from "./config";

const fetchUser = (webtoken: string) => {
  return userApi.getUser({
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

const fetchUserCreditsPackage = (webtoken: string) => {
  return userApi.getCurrentCreditsPackage({
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

const fetchUserUsageCredits = (webtoken: string) => {
  return userApi.getUserUsageCredits({
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

const fetchUserApiKeyLimits = (
  webtoken: string,
  payload: GetApiKeyLimitsRequest
) => {
  return userApi.getApiKeyLimits(payload, {
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

const fetchUserApiKeys = (webtoken: string) => {
  return userApi.listApiKeys({
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};
const createUserApiKey = (
  webtoken: string,
  payload: CreateApiKeyOperationRequest
) => {
  return userApi.createApiKey(payload, {
    headers: {
      Authorization: `Bearer ${webtoken}`,
      "Content-Type": "application/json",
    },
  });
};

export const userProvider = {
  fetchUser,
  fetchUserApiKeys,
  fetchUserApiKeyLimits,
  createUserApiKey,
  fetchUserCreditsPackage,
  fetchUserUsageCredits,
};
