export enum AuthenticationStatusEnum {
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}

export type WebtokenPayload = {
  readonly token: string;
};

export interface IAuthState {
  readonly registrationEmailDispatched: boolean;
  readonly passwordReset: boolean;
  readonly loading: boolean;
}
