import { LAYOUT_OPTIONS } from "@cleanlist-ai/portal/constants";
import logoImg from "./assets/dark-logo.svg";
import logoIconImg from "./assets/icon.svg";

enum MODE {
  DARK = "dark",
  LIGHT = "light",
}

export const portalConfig = {
  title: "Cleanlist",
  description: "Cleanlist Portal",
  logo: logoImg,
  icon: logoIconImg,
  mode: MODE.LIGHT,
  layout: LAYOUT_OPTIONS.HYDROGEN,
  // TODO: favicon
};
