import { SVGProps } from 'react';

export const CleanlistSvg = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="2.1875 9 357.7185 55" width="357.7185" height={55} {...props}>
      <path
        d="M44.4375 57.125C42.25 59.25 39.6562 60.9167 36.6562 62.125C33.6562 63.3333 30.3958 63.9375 26.875 63.9375C23.3125 63.9375 20.0312 63.3333 17.0312 62.125C14.0312 60.8958 11.4167 59.1875 9.1875 57C6.97917 54.7917 5.26042 52.2083 4.03125 49.25C2.80208 46.2917 2.1875 43.0625 2.1875 39.5625C2.1875 36.0625 2.80208 32.8333 4.03125 29.875C5.26042 26.8958 6.97917 24.3125 9.1875 22.125C11.4167 19.9375 14.0312 18.2396 17.0312 17.0312C20.0312 15.8021 23.3125 15.1875 26.875 15.1875C30.375 15.1875 33.6146 15.7917 36.5938 17C39.5938 18.2083 42.1875 19.8854 44.375 22.0312L37.6562 28.7812C36.3438 27.1771 34.7396 25.9167 32.8438 25C30.9688 24.0625 28.9271 23.5938 26.7188 23.5938C24.6146 23.5938 22.6667 24 20.875 24.8125C19.1042 25.6042 17.5521 26.7292 16.2188 28.1875C14.8854 29.625 13.8542 31.3125 13.125 33.25C12.3958 35.1875 12.0312 37.2812 12.0312 39.5312C12.0312 41.8229 12.3958 43.9375 13.125 45.875C13.8542 47.8125 14.8854 49.5104 16.2188 50.9688C17.5521 52.4062 19.1042 53.5312 20.875 54.3438C22.6667 55.1354 24.6146 55.5312 26.7188 55.5312C28.9271 55.5312 30.9688 55.0729 32.8438 54.1562C34.7188 53.2188 36.3125 51.9479 37.625 50.3438L44.4375 57.125Z"
        style={{ fill: 'rgb(255, 255, 255)' }}
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M82.4375 64H51.8125V17.125H61.4375V55.5938H82.4375V64Z"
        style={{ fill: 'rgb(255, 255, 255)' }}
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M87.8125 63V16.125H118.062V24.5312H97.4375V35.1875H116.781V43.625H97.4375V54.5938H118.375V63H87.8125Z"
        style={{ fill: 'rgb(255, 255, 255)' }}
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M133.844 63H123.75L142.438 16.125H152.594L171.25 63H161.156L157.312 52.9375H137.688L133.844 63ZM140.844 44.5H154.188L147.5 27.125L140.844 44.5Z"
        style={{ fill: 'rgb(255, 255, 255)' }}
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M218.406 63H207.344L185.438 31V63H175.812V16.125H186.875L208.781 48.1875V16.125H218.406V63Z"
        style={{ fill: 'rgb(255, 255, 255)' }}
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M257.438 63H226.812V16.125H236.438V54.5938H257.438V63Z"
        fill="#3E8BFF"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M284.625 57.9375L289.188 50.5938C290.625 51.7604 292.01 52.7188 293.344 53.4688C294.698 54.1979 296.031 54.7396 297.344 55.0938C298.656 55.4271 300 55.5938 301.375 55.5938C303 55.5938 304.406 55.3646 305.594 54.9062C306.781 54.4479 307.698 53.7917 308.344 52.9375C309.01 52.0833 309.344 51.0938 309.344 49.9688C309.344 48.6562 308.927 47.5521 308.094 46.6562C307.26 45.7604 306.167 44.9792 304.812 44.3125C303.479 43.625 302.031 42.9688 300.469 42.3438C298.948 41.7188 297.417 41.0417 295.875 40.3125C294.354 39.5625 292.948 38.6771 291.656 37.6562C290.385 36.6354 289.354 35.3958 288.562 33.9375C287.792 32.4583 287.406 30.6667 287.406 28.5625C287.406 25.8125 288.094 23.4375 289.469 21.4375C290.865 19.4375 292.771 17.8958 295.188 16.8125C297.604 15.7292 300.365 15.1875 303.469 15.1875C305.656 15.1875 307.927 15.4792 310.281 16.0625C312.656 16.6458 315.021 17.5312 317.375 18.7188L313.594 26.5C312.115 25.6042 310.521 24.8958 308.812 24.375C307.104 23.8542 305.5 23.5938 304 23.5938C302.646 23.5938 301.458 23.8021 300.438 24.2188C299.438 24.6354 298.656 25.2188 298.094 25.9688C297.531 26.6979 297.25 27.5625 297.25 28.5625C297.25 29.75 297.656 30.7812 298.469 31.6562C299.281 32.5104 300.333 33.2708 301.625 33.9375C302.938 34.6042 304.344 35.2708 305.844 35.9375C307.385 36.6042 308.938 37.3333 310.5 38.125C312.083 38.9167 313.521 39.8542 314.812 40.9375C316.125 42 317.177 43.2812 317.969 44.7812C318.781 46.2604 319.188 48.0417 319.188 50.125C319.188 52.875 318.458 55.2917 317 57.375C315.542 59.4375 313.51 61.0521 310.906 62.2188C308.302 63.3646 305.271 63.9375 301.812 63.9375C298.646 63.9375 295.615 63.4271 292.719 62.4062C289.823 61.3854 287.125 59.8958 284.625 57.9375Z"
        fill="#3E8BFF"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M346.062 63H336.438V24.5312H322.625V16.125H359.906V24.5312H346.062V63Z"
        fill="#3E8BFF"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <rect
        x={267}
        y={55}
        width={8}
        height={8}
        rx={1}
        fill="#34B94A"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <rect
        x={267}
        y={43}
        width={8}
        height={8}
        rx={1}
        fill="#34B94A"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <rect
        x={267}
        y={31}
        width={8}
        height={8}
        rx={1}
        fill="#34B94A"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
      <path
        d="M276.515 10.7114C272.243 10.271 269.873 12.418 269.083 13.3124C268.522 13.8676 268.081 14.5498 267.815 15.3359C267.441 16.4478 267.478 17.5926 267.844 18.6175C269.903 16.0444 273.351 15.5634 274.31 15.4776C273.191 16.0206 268.267 18.6661 267.165 23.6603C267.153 23.7012 267.139 23.7412 267.129 23.7826C267.102 23.8863 267.076 23.9899 267.054 24.0971C267.041 24.1578 267.026 24.218 267.021 24.2802C266.995 24.3975 266.957 24.6998 267.165 24.7787L267.868 24.9891C267.868 24.9891 268.24 25.0882 268.232 24.6882C268.24 23.3533 268.605 21.8578 269.534 20.9561C270.045 21.3601 270.643 21.6806 271.312 21.8879C273.009 22.4127 274.779 22.0952 276.129 21.1739C280.922 18.3191 283 9 283 9C281.214 11.2557 276.515 10.7117 276.515 10.7117L276.515 10.7114ZM274.548 15.4688L274.547 15.4607C274.559 15.4607 274.566 15.4587 274.566 15.4587C274.561 15.4627 274.554 15.4662 274.548 15.4688Z"
        fill="#34B94A"
        transform="matrix(1, 0, 0, 1, 0, -1.4210854715202004e-14)"
      />
    </svg>
  );
};
