import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, PinCode } from "rizzui";
import * as yup from "yup";

const otpSchema = yup
  .object({
    otp: yup.string().required(),
  })
  .required();

type OTPFormValues = yup.InferType<typeof otpSchema>;

export const OTPForm = () => {
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<OTPFormValues>({ resolver: yupResolver(otpSchema) });

  const onFormSubmit: SubmitHandler<OTPFormValues> = async ({ otp }) => {
    console.log(otp);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <div className="space-y-10">
        <PinCode
          variant="outline"
          setValue={(value) => setValue("otp", String(value))}
          size="lg"
          className="lg:justify-start"
        />
        <Button
          className="w-full text-base font-medium"
          type="submit"
          size="lg"
        >
          Confirm OTP
        </Button>
        <div>
          <Button
            className="-mt-4 w-full p-0 text-base font-medium text-primary underline lg:inline-flex lg:w-auto"
            type="submit"
            variant="text"
          >
            Resend OTP
          </Button>
        </div>
      </div>
    </form>
  );
};
