import { setPhase } from "../layout";
import { PHASE } from "../layout/types";
import { useAppDispatch, useAppSelector } from "./store-hooks";

export const usePhases = () => {
  const dispatch = useAppDispatch();
  const { phase } = useAppSelector((state) => state.layout);

  const setPhases = (phase: PHASE) => {
    dispatch(setPhase(phase));
  };

  const gotoNextPhase = () => {
    dispatch(setPhase(phase + 1));
  };

  const gotoPrevPhase = () => {
    dispatch(setPhase(phase > PHASE.ONE ? phase - 1 : phase));
  };
  const resetPhases = () => {
    dispatch(setPhase(PHASE.ONE));
  };

  return {
    phase,
    setPhases,
    gotoNextPhase,
    gotoPrevPhase,
    resetPhases,
  };
};
