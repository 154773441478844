import { useAppSelector, usePhases } from "@cleanlist-ai/portal/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { PiBroom } from "react-icons/pi";
import {
  AdvancedCheckbox,
  CheckboxGroup,
  FieldError,
  Radio,
  RadioGroup,
  Text,
} from "rizzui";
import * as yup from "yup";
import { PhasesBreadcrumb } from "./phases-breadcrumb.component";

type CleaningOption = {
  value: string;
  name: string;
  description: string;
  icon: ReactNode;
};

const cleaningSchema = yup
  .object({
    cleaningOptions: yup
      .array()
      .of(yup.string().required())
      .min(1, "Please choose a cleaning option."),
    chooseEveryCleaningProcess: yup.string().required(),
  })
  .required();

type CleaningFormValues = yup.InferType<typeof cleaningSchema>;

export const PhaseTwo = () => {
  const { phase, gotoNextPhase } = usePhases();
  const { cleaningOptions: cleaningProcesses } = useAppSelector(
    (state) => state.cleaner
  );
  const cleaningOptions = cleaningProcesses.map(
    (process) =>
      ({
        value: process.id,
        name: process.name,
        description: process.description,
        icon: <PiBroom className="h-8 w-8" />,
      }) as CleaningOption
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CleaningFormValues>({
    resolver: yupResolver(cleaningSchema),
    defaultValues: {
      cleaningOptions: [],
      chooseEveryCleaningProcess: "yes",
    },
  });

  useEffect(() => {
    setValue(
      "cleaningOptions",
      cleaningOptions.map((option) => option.value)
    );
  }, [cleaningOptions, setValue]);

  useEffect(() => {
    if (errors.cleaningOptions) {
      toast.error(errors.cleaningOptions.message as string);
    }
  }, [errors]);

  const chooseEveryCleaningProcess = watch("chooseEveryCleaningProcess");

  useEffect(() => {
    if (chooseEveryCleaningProcess === "yes") {
      setValue(
        "cleaningOptions",
        cleaningOptions.map((option) => option.value)
      );
    } else {
      setValue("cleaningOptions", []);
    }
  }, [chooseEveryCleaningProcess]);

  const onFormSubmit: SubmitHandler<CleaningFormValues> = ({
    cleaningOptions,
  }) => {
    console.log("cleaningOptions", cleaningOptions);
    gotoNextPhase();
  };

  return (
    <>
      <div className="col-span-full flex flex-col justify-center @5xl:col-span-4 @6xl:col-span-5">
        <PhasesBreadcrumb
          heading="Cleaning Configuration"
          description="Configure which cleaning tools to use automatically for when exporting leads to a CRM."
        />
      </div>
      <div className="col-span-full flex items-center justify-center @5xl:col-span-8 @6xl:col-span-7">
        <form
          id={`rhf-${phase.toString()}`}
          onSubmit={handleSubmit(onFormSubmit)}
          className="grid flex-grow gap-6 rounded-lg bg-white p-5 @4xl:p-7 dark:bg-gray-0"
        >
          <div className="grid gap-4">
            <Text className="font-semibold text-gray-900">
              Choose every cleaning process
            </Text>
            <Controller
              name="chooseEveryCleaningProcess"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <RadioGroup
                    value={value}
                    setValue={onChange}
                    className="flex gap-4"
                  >
                    <Radio label="Yes" value="yes" name="yes" />
                    <Radio label="No" value="no" name="no" />
                  </RadioGroup>
                );
              }}
            />
            {errors.chooseEveryCleaningProcess && (
              <FieldError error={errors.chooseEveryCleaningProcess?.message} />
            )}
          </div>
          <div className="grid gap-4">
            <Text className="font-semibold text-gray-900">
              Cleaning Options
            </Text>
            <Controller
              name="cleaningOptions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  values={value ?? []}
                  setValues={onChange}
                  className="col-span-full grid grid-cols-2 gap-4 @3xl:grid-cols-3 @4xl:gap-6 @7xl:grid-cols-3"
                >
                  {cleaningOptions.map((option) => (
                    <AdvancedCheckbox
                      key={option.value}
                      value={option.value}
                      className=" [&_.rizzui-advanced-checkbox]:px-6 [&_.rizzui-advanced-checkbox]:py-6"
                      inputClassName="[&~span]:border-0 [&~span]:ring-1 [&~span]:ring-gray-200 [&~span:hover]:ring-primary [&:checked~span:hover]:ring-primary [&:checked~span]:border-1 [&:checked~.rizzui-advanced-checkbox]:ring-2 [&~span_.icon]:opacity-0 [&:checked~span_.icon]:opacity-100"
                    >
                      <span className="block h-8 w-8">{option.icon}</span>
                      <p className="font-semibold">{option.name}</p>
                      <p>{option.description}</p>
                    </AdvancedCheckbox>
                  ))}
                </CheckboxGroup>
              )}
            />
          </div>
        </form>
      </div>
    </>
  );
};
