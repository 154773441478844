import {
  MODE,
  setThemeMode,
  useAppDispatch,
  useAppSelector,
} from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import Link from "next/link";
import { PiBellSimpleRingingFill, PiMoonFill, PiSunFill } from "react-icons/pi";
import { ActionIcon, Badge } from "rizzui";
import { Logo } from "../logo.component";
import { MobileHamburgerButton } from "../mobile-hamburger-button.component";
import { Menu } from "./menu.component";
import { NotificationDropdown } from "./notification-dropdown.component";
import { QueryWidget } from "./query-widget.component";
import { StickyHeader } from "./sticky-header.component";

const HeaderMenuRight = () => {
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.layout);

  const toggleTheme = () => {
    dispatch(setThemeMode(mode === MODE.DARK ? MODE.LIGHT : MODE.DARK));
  };

  return (
    <div className="ms-auto grid shrink-0 grid-cols-4 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
      <NotificationDropdown>
        <ActionIcon
          aria-label="Notification"
          variant="text"
          className={cn(
            "relative h-[34px] w-[34px] overflow-hidden rounded-full shadow backdrop-blur-md before:absolute before:h-full before:w-full before:-rotate-45 before:rounded-full before:bg-gradient-to-l before:from-orange-dark/25 before:via-orange-dark/0 before:to-orange-dark/0 dark:bg-gray-100 md:h-9 md:w-9 3xl:h-10 3xl:w-10"
          )}
        >
          <PiBellSimpleRingingFill className="h-[18px] w-auto 3xl:h-5" />
          <Badge
            renderAsDot
            color="warning"
            enableOutlineRing
            className="absolute right-1 top-2.5 -translate-x-1 -translate-y-1/4"
          />
        </ActionIcon>
      </NotificationDropdown>
      <ActionIcon
        onClick={toggleTheme}
        variant="text"
        className={cn(
          "relative h-[34px] w-[34px] overflow-hidden rounded-full shadow backdrop-blur-md before:absolute before:h-full before:w-full before:-rotate-45 before:rounded-full before:bg-gradient-to-l before:from-orange-dark/25 before:via-orange-dark/0 before:to-orange-dark/0 dark:bg-gray-100 md:h-9 md:w-9 3xl:h-10 3xl:w-10"
        )}
      >
        {mode === MODE.DARK ? (
          <PiSunFill className="h-[18px] w-auto 3xl:h-5" />
        ) : (
          <PiMoonFill className="h-[18px] w-auto 3xl:h-5" />
        )}
      </ActionIcon>
      <Menu />
    </div>
  );
};

export const GlobalHeader = () => {
  return (
    <StickyHeader className="z-[990] 2xl:py-5 3xl:px-8 4xl:px-10">
      <div className="flex w-full max-w-2xl items-center">
        <MobileHamburgerButton />
        <Link
          href="/"
          className="me-4 w-9 shrink-0 text-gray-800 hover:text-gray-900 lg:me-5 xl:hidden"
        >
          <Logo icon />
        </Link>
        <QueryWidget className="[&_.search-command]:lg:bg-gray-900 [&_.search-command]:lg:text-gray-0" />
      </div>
      <HeaderMenuRight />
    </StickyHeader>
  );
};
