import { cn } from "@cleanlist-ai/portal/utils";
import { ReactNode } from "react";
import { Title } from "rizzui";
import { Breadcrumb } from "../breadcrumb.component";
import { MenuEntry } from "./global/menu";

type HeaderProps = {
  heading: string;
  breadcrumb: MenuEntry[];
  children?: ReactNode;
  className?: string;
};

export const Header = ({
  heading,
  breadcrumb,
  children,
  className,
}: HeaderProps) => {
  return (
    <header
      className={cn(
        "mb-6 flex flex-col @lg:flex-row @lg:items-center @lg:justify-between xs:-mt-2 lg:mb-7",
        className
      )}
    >
      <div>
        <Title as="h2" className="mb-2 text-[22px] lg:text-2xl 4xl:text-[26px]">
          {heading}
        </Title>

        <Breadcrumb
          separator=""
          separatorVariant="circle"
          className="flex-wrap"
        >
          {breadcrumb.map((item) => (
            <Breadcrumb.Item
              key={item.name}
              {...(item?.href && { href: item?.href })}
            >
              {item.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      {children}
    </header>
  );
};
