import { SignedOut, useAuth, useSignIn, useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiLogIn } from "react-icons/bi";
import { Button, Input, Password, Text } from "rizzui";
import * as yup from "yup";
import { Loader } from "../loader.component";
import { routes } from "../routes";

const loginSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().min(12).max(32).required(),
  })
  .required();

type LoginFormValues = yup.InferType<typeof loginSchema>;

export type ClerkAuthError = {
  errors: {
    code: string;
    message: string;
    longMessage: string;
  }[];
};

export const LoginForm = () => {
  const { isLoaded, signIn, setActive } = useSignIn();
  const { isSignedIn: isAuthenticated } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const { user } = useUser();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({ resolver: yupResolver(loginSchema) });
  const { ref: emailRef, ...registerEmail } = registerField("email");
  const { ref: passwordRef, ...registerPassword } = registerField("password");

  const onFormSubmit: SubmitHandler<LoginFormValues> = async ({
    email,
    password,
  }) => {
    if (!isLoaded) {
      return;
    }
    try {
      setLoading(true);
      const result = await signIn?.create({
        identifier: email,
        password,
      });
      if (result?.status === "complete") {
        await setActive({ session: result.createdSessionId });
        router.replace(routes.dashboard);
      } else {
        toast.error("Problem logging in. Please try again!");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error((err as ClerkAuthError).errors[0].longMessage);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      toast.success(`Greetings ${user?.fullName}`);
      if (!user?.unsafeMetadata.completedOnboarding) {
        return router.replace("/auth/onboarding");
      }
      return router.replace(routes.auth.onboarding);
    }
  }, [isAuthenticated, router, user]);

  return (
    <SignedOut>
      <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
        <div className="space-y-5">
          <Input
            type="email"
            size="lg"
            label="Email"
            placeholder="Enter email"
            color="info"
            className="[&>label>span]:font-medium"
            inputClassName="text-sm"
            ref={emailRef}
            {...registerEmail}
            error={errors.email?.message}
          />
          <Password
            label="Password"
            placeholder="Enter password"
            size="lg"
            className="[&>label>span]:font-medium"
            inputClassName="text-sm"
            color="info"
            ref={passwordRef}
            {...registerPassword}
            error={errors.password?.message}
          />
          <Text className="flex">
            <Link
              href={routes.auth.forgotPassword}
              className="ml-auto h-auto text-sm font-semibold text-blue underline transition-colors hover:text-gray-900 hover:no-underline"
            >
              Forgot Password?
            </Link>
          </Text>
          <Text>
            By logging in, I agree to the Terms of service and Privacy policy. I
            also agree to receive emails and communication relating to
            Cleanlist.ai offerings.
          </Text>
          <Button className="w-full" type="submit" size="lg">
            <span>{loading ? <Loader /> : "Login"}</span>{" "}
            <BiLogIn className="ms-2 mt-0.5 h-6 w-6" />
          </Button>
        </div>
      </form>
      <Text className="mt-6 text-center leading-loose text-gray-500 lg:mt-8 lg:text-start">
        Not part of Cleanlist?{" "}
        <Link
          href="/auth/register"
          className="font-semibold text-gray-700 transition-colors hover:text-blue"
        >
          Register
        </Link>
      </Text>
    </SignedOut>
  );
};
