"use client";
import { useSignIn } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiSolidKey } from "react-icons/bi";
import { Button, Input, Password, Text } from "rizzui";
import * as yup from "yup";
import { ClerkAuthError } from ".";
import {
  setResetPassword,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import { Loader } from "../loader.component";
import { routes } from "../routes";

const resetPasswordSchema = yup
  .object({
    code: yup.string().required(),
    password: yup.string().min(12).max(32).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

type ResetPasswordFormValues = yup.InferType<typeof resetPasswordSchema>;

const ResetPasswordForm = () => {
  const { loading } = useAppSelector((state) => state.auth);
  const { isLoaded, signIn, setActive } = useSignIn();

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(resetPasswordSchema),
  });
  const { ref: codeRef, ...code } = registerField("code");
  const { ref: passwordRef, ...password } = registerField("password");
  const { ref: confirmPasswordRef, ...confirmPassword } =
    registerField("confirmPassword");

  const navigate = useRouter();
  const dispatch = useAppDispatch();

  const handleGoBack = (): void => {
    dispatch(setResetPassword(false));
  };

  const onFormSubmit: SubmitHandler<ResetPasswordFormValues> = async ({
    code,
    password,
  }) => {
    if (!isLoaded) return;

    try {
      const result = await signIn?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code,
        password,
      });

      if (result?.status === "complete") {
        await setActive({ session: result.createdSessionId });
        navigate.replace(routes.dashboard);
        dispatch(setResetPassword(false));
      }
    } catch (err) {
      const extraContext =
        " or your code has expired! Click 'Go Back' to request another code!";
      toast.error(
        <Text>
          {(err as ClerkAuthError).errors[0].longMessage + extraContext}
        </Text>
      );
    }
  };

  return (
    <>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Input
          size="lg"
          label="Code"
          placeholder="Enter code"
          color="info"
          className="[&>label>span]:font-medium"
          inputClassName="text-sm"
          type="text"
          ref={codeRef}
          {...code}
          error={errors.code?.message}
        />
        <Password
          size="lg"
          label="Password"
          placeholder="Enter new password"
          color="info"
          className="[&>label>span]:font-medium"
          inputClassName="text-sm"
          ref={passwordRef}
          {...password}
          error={errors.password?.message}
        />
        <Password
          size="lg"
          label="Confirm Password"
          placeholder="Confirm password"
          color="info"
          className="[&>label>span]:font-medium"
          inputClassName="text-sm"
          ref={confirmPasswordRef}
          {...confirmPassword}
          error={errors.confirmPassword?.message}
        />
        <Button className="w-full" type="submit" size="lg">
          <span>{loading ? <Loader /> : "Reset Password"}</span>{" "}
          <BiSolidKey className="ms-2 mt-0.5 h-6 w-6" />
        </Button>
      </form>

      <Text className="mt-6 text-center leading-loose text-gray-500 lg:mt-8 lg:text-start">
        Something wrong?{" "}
        <Button
          className="px-0 font-semibold text-blue transition-colors hover:text-gray-900"
          variant="text"
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Text>
    </>
  );
};

export default ResetPasswordForm;
