import {
  DrawerPlacements,
  setMobileDrawer,
  useAppDispatch,
  useAppSelector,
} from "@cleanlist-ai/portal/store";

export const useDrawer = () => {
  const dispatch = useAppDispatch();
  const { mobileDrawer } = useAppSelector((state) => state.layout);

  const openDrawer = (placement: DrawerPlacements, width?: string) => {
    dispatch(
      setMobileDrawer({
        displayMobileDrawer: true,
        mobileDrawerPlacement: placement,
        mobileDrawerWidth: width,
      })
    );
  };

  const closeDrawer = () => {
    dispatch(setMobileDrawer({ ...mobileDrawer, displayMobileDrawer: false }));
  };

  return {
    openDrawer,
    closeDrawer,
    mobileDrawer,
  };
};
