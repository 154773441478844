import { cn } from "@cleanlist-ai/portal/utils";
import { useOrganizationList } from "@clerk/nextjs";
import { useEffect, useMemo, useState } from "react";
import { Avatar, Select, SelectOption, Text } from "rizzui";

function renderDisplayValue(value: SelectOption) {
  return (
    <div className="flex items-center gap-3">
      <Avatar name={value?.label} src={value?.avatar} size="sm" />
      <div>
        <Text fontWeight="medium" className="text-gray-900">
          {value.label}
        </Text>
        <Text className="text-gray-500">Pick Organization</Text>
      </div>
    </div>
  );
}

function renderOptionDisplayValue(option: SelectOption) {
  return (
    <div className="flex items-center gap-3">
      <Avatar name={option.label} src={option.avatar} size="sm" />
      <div>
        <Text fontWeight="medium">{option.label}</Text>
        <Text>{String(option.value).slice(0, 20) + "..."}</Text>
      </div>
    </div>
  );
}

type OrganizationDropdownProps = {
  className?: string;
  selectClassName?: string;
  dropdownClassName?: string;
  suffixClassName?: string;
};

export const OrganizationDropdown = ({
  className,
  selectClassName,
  dropdownClassName,
  suffixClassName,
}: OrganizationDropdownProps) => {
  const { userMemberships, isLoaded } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  const options = useMemo(
    () =>
      userMemberships.data?.map((membership) => ({
        label: membership.organization.name,
        value: membership.organization.id,
        avatar: membership.organization.hasImage
          ? membership.organization.imageUrl
          : `https://ui-avatars.com/api/?name=${encodeURIComponent(
              membership.organization.name ?? "N/A"
            )}`,
      })) || [],
    [userMemberships.data]
  );

  const [value, setValue] = useState<SelectOption | undefined>(undefined);

  useEffect(() => {
    if (!value && options.length > 0) {
      setValue(options[0]);
    }
  }, [options, value]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Select
      options={options ?? []}
      value={value}
      placeholder="Organization"
      disabled={!options?.length}
      onChange={setValue}
      displayValue={(value: SelectOption) => renderDisplayValue(value)}
      getOptionDisplayValue={(option) => renderOptionDisplayValue(option)}
      selectClassName={cn(
        "h-16 outline-0 border-2 ring-0 border-gray-100 hover:!border-gray-100 hover:!ring-0 focus:border-gray-100 focus:!ring-0",
        selectClassName
      )}
      className={cn(className)}
      dropdownClassName={cn("z-[9999] max-w-[250px]", dropdownClassName)}
      suffixClassName={suffixClassName}
      optionClassName={cn("dark:hover:bg-gray-300")}
    />
  );
};
