export * from './apple-pay-svg.component';
export * from './cleanlist-svg.component';
export * from './cloud-task-svg.component';
export * from './eye-svg.component';
export * from './hand-wave-svg.component';
export * from './mastercard-svg.component';
export * from './pencil-svg.component';
export * from './trash-svg.component';
export * from './underline-svg.component';
export * from './visa-svg.component';
