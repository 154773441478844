import { cn } from "@cleanlist-ai/portal/utils";
import Link from "next/link";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  PiFileTextDuotone,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import {
  ActionIcon,
  Button,
  Empty,
  Input,
  SearchNotFoundIcon,
  Title,
} from "rizzui";
import { routes } from "../../routes";

const links = [
  // label start
  {
    name: "Apps",
  },
  // label end
  {
    name: "Cleanlist Portal",
    href: routes.external.docs,
  },
  {
    name: "Cleanlist Extension",
    href: routes.external.docs,
  },
  {
    name: "Faq",
  },
  {
    name: "How does Cleanlist credit system function?",
    href: routes.external.docs,
  },
  {
    name: "How does Cleanlist email finder function?",
    href: routes.external.docs,
  },
  {
    name: 'What are "Cleaned" entries?',
    href: routes.external.docs,
  },
];

type QueryInputProps = {
  onClose: () => void;
};

export const QueryInput = ({ onClose }: QueryInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  let menuItemsFiltered = links;

  if (searchText.length > 0) {
    menuItemsFiltered = links.filter((item) => {
      const label = item.name;
      return (
        label.match(searchText.toLowerCase()) ||
        (label.toLowerCase().match(searchText.toLowerCase()) && label)
      );
    });
  }

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
    return () => {
      inputRef.current = null;
    };
  }, []);

  return (
    <>
      <div className="flex items-center px-5 py-4">
        <Input
          variant="flat"
          value={searchText}
          ref={inputRef}
          onChange={(e) => setSearchText(() => e.target.value)}
          placeholder="Query..."
          className="flex-1"
          prefix={
            <PiMagnifyingGlassBold className="h-[18px] w-[18px] text-gray-600" />
          }
          suffix={
            searchText && (
              <Button
                size="sm"
                variant="text"
                className="h-auto w-auto px-0"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchText(() => "");
                }}
              >
                Clear
              </Button>
            )
          }
        />
        <ActionIcon
          variant="text"
          size="sm"
          className="ms-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <PiXBold className="h-5 w-5" />
        </ActionIcon>
      </div>

      <div className="custom-scrollbar max-h-[60vh] overflow-y-auto border-t border-gray-300 px-2 py-4">
        {menuItemsFiltered.length === 0 ? (
          <Empty
            className="scale-75"
            image={<SearchNotFoundIcon />}
            text="No Result Found"
            textClassName="text-xl"
          />
        ) : (
          <Title as="h6" className="mb-5 px-3 font-semibold dark:text-gray-700">
            Quick Page Links
          </Title>
        )}

        {menuItemsFiltered.map((item, index) => {
          return (
            <Fragment key={item.name + "-" + index}>
              {item?.href ? (
                <Link
                  href={item?.href as string}
                  className="relative my-0.5 flex items-center rounded-lg px-3 py-2 text-sm hover:bg-gray-100 focus:outline-none focus-visible:bg-gray-100 dark:hover:bg-gray-50/50 dark:hover:backdrop-blur-lg"
                >
                  <span className="inline-flex items-center justify-center rounded-md border border-gray-300 p-2 text-gray-500">
                    <PiFileTextDuotone className="h-5 w-5" />
                  </span>

                  <span className="ms-3 grid gap-0.5">
                    <span className="font-medium capitalize text-gray-900 dark:text-gray-700">
                      {item.name}
                    </span>
                    <span className="text-gray-500">
                      {item?.href as string}
                    </span>
                  </span>
                </Link>
              ) : (
                <Title
                  as="h6"
                  className={cn(
                    "mb-1 px-3 text-xs font-semibold uppercase tracking-widest text-gray-500 dark:text-gray-500",
                    index !== 0 && "mt-6 4xl:mt-7"
                  )}
                >
                  {item.name}
                </Title>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
