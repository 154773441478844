import {
  fetchCRMOptions,
  fetchCleaningOptions,
  useAppDispatch,
} from "@cleanlist-ai/portal/store";
import { useAuth, useUser } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { ReactNode, useEffect } from "react";
import toast from "react-hot-toast";
import { OnboardingHeader } from "../onboarding";
import { routes } from "../routes";

type OnboardingLayoutProps = {
  children: ReactNode;
};
export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  const navigate = useRouter();
  const dispatch = useAppDispatch();
  const { getToken, isLoaded, isSignedIn: isAuthenticated } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (!token) {
        toast.error("Failed to fetch authentication token.");
        return;
      }
      dispatch(fetchCRMOptions({ token }));
      dispatch(fetchCleaningOptions());
    })();
  }, []);

  useEffect(() => {
    if (isLoaded && !isAuthenticated) {
      navigate.replace(routes.auth.login);
      return;
    }
  }, [isAuthenticated, isLoaded]);

  useEffect(() => {
    if (user?.unsafeMetadata.completedOnboarding) {
      navigate.replace(routes.dashboard);
    }
  }, [user]);

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#136A8A] to-[#267871] @container">
      <OnboardingHeader />
      {children}
    </div>
  );
};
