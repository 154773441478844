import {
  authProvider,
  setResetPassword,
  useAppDispatch,
  useAppSelector,
} from "@cleanlist-ai/portal/store";
import { useSignIn } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiLogIn } from "react-icons/bi";
import { Button, Input, Text } from "rizzui";
import * as yup from "yup";
import { ClerkAuthError } from ".";
import { Loader } from "../loader.component";
import { routes } from "../routes";

const RECAPTCHA_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_KEY ??
  "6LcvEnopAAAAAC2HKmtsFwzrGkFESSm6IDUxvMvk";

const forgotPasswordSchema = yup
  .object({
    email: yup.string().email().required(),
    recaptcha: yup.string().required(),
  })
  .required();

type ForgotPasswordFormValues = yup.InferType<typeof forgotPasswordSchema>;

export const ForgotPasswordForm = () => {
  const { signIn } = useSignIn();
  const { loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const {
    register: registerField,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const { ref: emailRef, ...registerEmail } = registerField("email");

  const onFormSubmit: SubmitHandler<ForgotPasswordFormValues> = async ({
    email,
    recaptcha,
  }) => {
    try {
      const res = await authProvider.verifyRecaptcha({
        verifyRecaptchaRequest: {
          recaptchaResponse: recaptcha,
        },
      });
      if (!res.success) {
        toast.error(<Text>ReCAPTCHA not verified</Text>);
        return;
      }
      await signIn?.create({
        strategy: "reset_password_email_code",
        identifier: email,
      });
      dispatch(setResetPassword(true));
    } catch (err) {
      const clerk_err = err as ClerkAuthError;
      toast.error(<Text>{clerk_err.errors[0].longMessage}</Text>);
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
        <div className="space-y-5">
          <Input
            type="email"
            size="lg"
            label="Email"
            placeholder="Enter email"
            color="info"
            className="[&>label>span]:font-medium"
            inputClassName="text-sm"
            ref={emailRef}
            {...registerEmail}
            error={errors.email?.message}
          />
          <ReCAPTCHA
            sitekey={RECAPTCHA_KEY}
            onChange={(value) => value && setValue("recaptcha", value)}
          />
          {errors.recaptcha && (
            <Text className="text-sm text-red-500">
              Please complete the recaptcha
            </Text>
          )}
          <Button className="w-full" type="submit" size="lg">
            <span>{loading ? <Loader /> : "Confirm"}</span>{" "}
            <BiLogIn className="ms-2 mt-0.5 h-6 w-6" />
          </Button>
        </div>
      </form>

      <Text className="mt-6 text-center leading-loose text-gray-500 lg:mt-8 lg:text-start">
        Changed your mind?{" "}
        <Link
          href={routes.auth.login}
          className="font-semibold text-gray-700 transition-colors hover:text-blue"
        >
          Login
        </Link>
      </Text>
    </>
  );
};
