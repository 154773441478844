import isString from "lodash/isString";
import { useEffect, useMemo, useState } from "react";
import { AnyObject } from "yup";

export function useTable<T extends AnyObject>(
  payload: T[],
  countPerPage = 10,
  initialFilterState?: Partial<AnyObject>
) {
  const [data, setData] = useState(payload);

  // If the payload changes, make certain to reflect that
  useEffect(() => {
    setData(payload);
  }, [payload]);

  /*
   * Dummy loading state.
   */
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  /*
   * Handle row selection
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const handleRowSelect = (recordKey: string) => {
    const selectedKeys = [...selectedRowKeys];
    if (selectedKeys.includes(recordKey)) {
      setSelectedRowKeys(selectedKeys.filter((key) => key !== recordKey));
    } else {
      setSelectedRowKeys([...selectedKeys, recordKey]);
    }
  };
  const handleSelectAll = () => {
    if (selectedRowKeys.length === data.length) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(data.map((record) => record.objectUrn));
    }
  };

  /*
   * Handle sorting
   */
  const [sortConfig, setSortConfig] = useState<AnyObject>({
    key: null,
    direction: null,
  });

  function sortData(
    data: T[],
    sortKey: keyof T,
    sortDirection: "asc" | "desc"
  ): T[] {
    return [...data].sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const sortedData = useMemo(() => {
    const newData = data;
    if (!sortConfig.key) {
      return newData;
    }
    return sortData(newData, sortConfig.key, sortConfig.direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig, data]);

  function handleSort(key: keyof T): void {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  }

  /*
   * Handle pagination
   */
  const [currentPage, setCurrentPage] = useState(1);
  function paginatedData(data: T[] = sortedData) {
    const start = (currentPage - 1) * countPerPage;
    const end = start + countPerPage;

    if (data.length > start) return data.slice(start, end);
    return data;
  }

  function handlePaginate(pageNumber: number) {
    setCurrentPage(pageNumber);
  }

  /*
   * Handle Filters and searching
   */
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<Record<string, any>>(
    initialFilterState ?? {}
  );

  function updateFilter(columnId: string, filterValue: string | any[]): void {
    if (!Array.isArray(filterValue) && !isString(filterValue)) {
      throw new Error("filterValue data type should be string or array of any");
    }

    if (Array.isArray(filterValue) && filterValue.length !== 2) {
      throw new Error("filterValue data must be an array of length 2");
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: filterValue,
    }));
  }

  function applyFilters() {
    const searchTermLower = searchTerm.toLowerCase();

    const filteredData = sortedData
      .filter((item) => {
        const isMatchingItem = Object.entries(filters).some(
          ([columnId, filterValue]) => {
            if (
              Array.isArray(filterValue) &&
              typeof filterValue[1] === "object"
            ) {
              const itemValue = new Date(item[columnId]);
              return (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                itemValue >= filterValue[0] && itemValue <= filterValue[1]
              );
            }
            if (
              Array.isArray(filterValue) &&
              typeof filterValue[1] === "string"
            ) {
              const itemPrice = Math.ceil(Number(item[columnId]));
              return (
                itemPrice >= Number(filterValue[0]) &&
                itemPrice <= Number(filterValue[1])
              );
            }
            if (isString(filterValue) && !Array.isArray(filterValue)) {
              const itemValue = item[columnId]?.toString().toLowerCase();
              if (itemValue !== filterValue.toString().toLowerCase()) {
                return false;
              }
              return true;
            }
          }
        );
        return isMatchingItem;
      })
      // global search after running filters
      .filter((item) =>
        Object.values(item).some((value) =>
          typeof value === "object"
            ? value &&
              Object.values(value).some(
                (nestedItem) =>
                  nestedItem &&
                  String(nestedItem).toLowerCase().includes(searchTermLower)
              )
            : value && String(value).toLowerCase().includes(searchTermLower)
        )
      );

    return filteredData;
  }

  /*
   * Handle searching
   */
  function handleSearch(searchValue: string): void {
    setSearchTerm(searchValue);
  }

  function searchedData() {
    if (!searchTerm) return sortedData;

    const searchTermLower = searchTerm.toLowerCase();

    return sortedData.filter((item) =>
      Object.values(item).some((value) =>
        typeof value === "object"
          ? value &&
            Object.values(value).some(
              (nestedItem) =>
                nestedItem &&
                String(nestedItem).toLowerCase().includes(searchTermLower)
            )
          : value && String(value).toLowerCase().includes(searchTermLower)
      )
    );
  }

  /*
   * Reset search and filters
   */
  function handleReset(): void {
    setData(() => payload);
    handleSearch("");
    if (initialFilterState) return setFilters(initialFilterState);
  }

  /*
   * Set isFiltered and final filtered data
   */
  const isFiltered = applyFilters().length > 0;
  function calculateTotalItems() {
    if (isFiltered) {
      return applyFilters().length;
    }
    if (searchTerm) {
      return searchedData().length;
    }
    return sortedData.length;
  }
  const filteredAndSearchedData = isFiltered ? applyFilters() : searchedData();
  console.log(filteredAndSearchedData);
  const tableData = paginatedData(filteredAndSearchedData);

  /*
   * Go to first page when data is filtered and searched
   */
  useEffect(() => {
    handlePaginate(1);
  }, [isFiltered, searchTerm]);

  /*
   * Handle delete
   */
  function handleDelete(id: keyof T | Array<keyof T>): void {
    const updatedData = Array.isArray(id)
      ? tableData.filter((item) => !id.includes(item.objectUrn as keyof T))
      : tableData.filter((item) => item.objectUrn !== id);

    setData(updatedData);
  }

  // useTable returns
  return {
    isLoading,
    isFiltered,
    tableData,
    // pagination
    currentPage,
    handlePaginate,
    totalItems: calculateTotalItems(),
    // sorting
    sortConfig,
    handleSort,
    // row selection
    selectedRowKeys,
    setSelectedRowKeys,
    handleRowSelect,
    handleSelectAll,
    // searching
    searchTerm,
    handleSearch,
    // filters
    filters,
    updateFilter,
    applyFilters,
    handleDelete,
    handleReset,
  };
}
