import { crmApi } from "./config";

const fetchCRMOptions = (webtoken: string) => {
  return crmApi.crmOptions({
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

export const crmProvider = {
  fetchCRMOptions,
};
