import { ReactNode } from "react";
import { GlobalHeader } from "./global-header.component";
import { GlobalNavbar } from "./global-navbar.component";

type GlobalLayoutProps = {
  children: ReactNode;
  displayNavbar?: boolean;
};
export const GlobalLayout = ({ children }: GlobalLayoutProps) => {
  return (
    <main className="flex min-h-screen flex-grow">
      <GlobalNavbar className="fixed hidden flex-col justify-between dark:bg-gray-50 xl:block" />

      <div className="flex w-full flex-col xl:ms-[270px] xl:w-[calc(100%-270px)] 2xl:ms-72 2xl:w-[calc(100%-288px)]">
        <GlobalHeader />
        <div className="flex flex-grow flex-col px-4 pb-6 pt-2 md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
          {children}
        </div>
      </div>
    </main>
  );
};
