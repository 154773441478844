import { cn } from "@cleanlist-ai/portal/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Fragment } from "react";
import { PiCaretDownBold, PiCommand } from "react-icons/pi";
import { Collapse, Title } from "rizzui";
import { menuEntries } from "./menu";

export const GlobalNavbarMenu = () => {
  const pathname = usePathname();
  return (
    <div className="mb-auto">
      <div className="pb-12">
        {menuEntries.map((entry, index) => {
          const isActive = pathname === (entry?.href as string);
          const pathnameExistInDropdowns = entry?.childrenMenu?.filter(
            (childEntry) => childEntry.href === pathname
          );
          const isDropdownOpen = Boolean(pathnameExistInDropdowns?.length);

          return (
            <Fragment key={entry.name + "-" + index}>
              {entry?.href ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {entry?.childrenMenu ? (
                    <Collapse
                      defaultOpen={isDropdownOpen}
                      header={({ open, toggle }) => (
                        <div
                          onClick={toggle}
                          className={cn(
                            "group relative mx-1.5 flex cursor-pointer items-center justify-between rounded-md px-3 py-2 font-medium lg:my-1 2xl:mx-5 2xl:my-2",
                            isDropdownOpen
                              ? "before:top-2/5 text-white before:absolute before:-start-3 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-white dark:text-primary dark:before:bg-primary 2xl:before:-start-5"
                              : "text-gray-300/70 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-500 dark:hover:text-gray-700"
                          )}
                        >
                          <span className="flex items-center">
                            {entry?.icon && (
                              <span
                                className={cn(
                                  "me-2 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[19px] [&>svg]:w-[19px]",
                                  isDropdownOpen
                                    ? "text-white dark:text-primary"
                                    : "text-gray-300/70 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-500"
                                )}
                              >
                                {entry?.icon}
                              </span>
                            )}
                            {entry.name}
                          </span>

                          <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                              "h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90",
                              open && "rotate-0 rtl:rotate-0"
                            )}
                          />
                        </div>
                      )}
                    >
                      {entry?.childrenMenu?.map((childEntry, index) => {
                        const isChildActive =
                          pathname === (childEntry?.href as string);

                        return (
                          <Link
                            href={childEntry?.href ?? "#"}
                            key={childEntry?.name + index}
                            className={cn(
                              "group mx-3.5 mb-0.5 flex items-center rounded-md px-2.5 py-2 font-medium capitalize last-of-type:mb-1 lg:last-of-type:mb-2 2xl:mx-5 2xl:px-3.5",
                              isChildActive
                                ? "text-gray-200 dark:text-gray-700"
                                : "text-gray-400 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-500 "
                            )}
                          >
                            <span
                              className={cn(
                                "me-[18px] ms-1 inline-flex h-1 w-1 rounded-full bg-current transition-all duration-200",
                                isChildActive
                                  ? "bg-primary ring-[1px] ring-primary "
                                  : "opacity-40 group-hover:bg-gray-700"
                              )}
                            />{" "}
                            {childEntry?.name}
                          </Link>
                        );
                      })}
                    </Collapse>
                  ) : (
                    <Link
                      href={entry?.href ?? "#"}
                      className={cn(
                        "group relative mx-3 my-0.5 flex items-center justify-between rounded-md px-3 py-2 font-medium capitalize lg:my-1 2xl:mx-5 2xl:my-2",
                        isActive
                          ? "bg-primary text-gray-0"
                          : "text-gray-700 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-700/90"
                      )}
                    >
                      <div className="flex w-full items-center truncate">
                        {entry?.icon && (
                          <span
                            className={cn(
                              "me-2 inline-flex h-5 w-5 items-center justify-center rounded-md duration-200 [&>svg]:h-[20px] [&>svg]:w-[20px]",
                              isActive
                                ? "text-gray-0"
                                : "text-gray-800 dark:text-gray-500 dark:group-hover:text-gray-700"
                            )}
                          >
                            {entry?.icon}
                          </span>
                        )}
                        <span className="truncate">{entry.name}</span>
                        {!!entry.keybind && (
                          <span
                            className={cn(
                              "ms-auto hidden items-center gap-1 rounded px-1 duration-200 xl:inline-flex",
                              isActive
                                ? "bg-gray-100/30 dark:bg-gray-0/20"
                                : "bg-gray-100 group-hover:bg-gray-300"
                            )}
                          >
                            <kbd>
                              <PiCommand
                                strokeWidth={1.3}
                                className="h-[15px] w-[15px]"
                              />
                            </kbd>
                            <kbd>{entry.keybind?.key}</kbd>
                          </span>
                        )}
                      </div>
                    </Link>
                  )}
                </>
              ) : (
                <Title
                  as="h6"
                  className={cn(
                    "mb-2 truncate px-6 text-[11px] font-medium uppercase tracking-widest text-gray-500 dark:text-gray-500 2xl:px-8",
                    index !== 0 && "mt-6 3xl:mt-7"
                  )}
                >
                  {entry.name}
                </Title>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
