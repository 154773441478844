import { CleanProspectsRequest } from "@cleanlist-ai/cleanse-api-client-lib";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cleanerProvider } from "../api";
import { WebtokenPayload } from "../auth/types";
import { CleanerErrorCodesEnum, ICleanerState } from "./types";

const initialState: ICleanerState = {
  originalProspects: [],
  cleanedProspects: [],
  cleaningOptions: [],
  loading: false,
  error: null,
};

export const cleanProspects = createAsyncThunk(
  "@@cleaner/cleanProspects",
  async (
    payload: WebtokenPayload & CleanProspectsRequest,
    { rejectWithValue }
  ) => {
    try {
      const { token, ...cleanProspectsPayload } = payload;
      return await cleanerProvider.cleanProspects(token, cleanProspectsPayload);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchCleaningOptions = createAsyncThunk(
  "@@cleaner/fetchCleaningOptions",
  async (_, { rejectWithValue }) => {
    try {
      return await cleanerProvider.fetchCleaningOptions();
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

const cleanerSlice = createSlice({
  name: "@@cleaner",
  initialState,
  reducers: {
    setOriginalLeads: (state, action) => {
      state.originalProspects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cleanProspects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cleanProspects.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: CleanerErrorCodesEnum.CLEAN_LEADS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(cleanProspects.fulfilled, (state, action) => {
        //   Object.assign(state, action.payload);
        state.cleanedProspects = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchCleaningOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCleaningOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: CleanerErrorCodesEnum.GET_CLEANING_OPTIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchCleaningOptions.fulfilled, (state, action) => {
        state.cleaningOptions = action.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setOriginalLeads } = cleanerSlice.actions;

export const cleanerReducer = cleanerSlice.reducer;
