export enum LAYOUT_OPTIONS {
  HYDROGEN = "hydrogen",
  HELIUM = "helium",
}

export const EXAMPLE_UID = "FC6723757651DB74";

export const ROW_PER_PAGE_OPTIONS = [
  {
    value: 5,
    name: "5",
  },
  {
    value: 10,
    name: "10",
  },
  {
    value: 15,
    name: "15",
  },
  {
    value: 20,
    name: "20",
  },
];

export const POSTHOG_FLAGS = {
  REGISTER_FLAG: "register-flag",
};
