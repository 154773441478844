import { useElementDimensions } from "@cleanlist-ai/portal/hooks";
import Image from "next/image";
import Confetti from "react-confetti";
import ConfettiPng from "../../assets/hat-confetti.png";

export const Congratulations = () => {
  const [ref, { width, height }] = useElementDimensions();
  return (
    <div ref={ref} className="col-span-full grid place-content-center">
      <figure className="relative mx-auto grid place-content-center">
        <Image
          src={ConfettiPng}
          alt="congratulations"
          priority
          className="mx-auto object-contain"
        />
        <figcaption className="mx-auto max-w-lg text-center">
          <h2 className="text-2xl text-white @7xl:text-3xl @[113rem]:text-4xl">
            Congratulations on completing the onboarding!
          </h2>
          <p className="mt-6 text-base text-white">
            You can proceed with using Cleanlist to extract, enrich, and clean
            prospects from LinkedIn.
          </p>
        </figcaption>
      </figure>
      <Confetti className="!fixed mx-auto" width={width} height={height} />
    </div>
  );
};
