import { fetchUserMetadata, useAppDispatch } from "@cleanlist-ai/portal/store";
import { RedirectToSignIn, useAuth, useUser } from "@clerk/nextjs";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect } from "react";
import toast from "react-hot-toast";
import { Loader } from "./loader.component";
import { routes } from "./routes";

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { isSignedIn: isAuthenticated, getToken, isLoaded } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      if (isLoaded) {
        if (!isAuthenticated) {
          router.replace(`/auth/login?from=${pathname}`);
          return;
        }
        if (!user?.unsafeMetadata.completedOnboarding) {
          router.replace("/auth/onboarding");
          return;
        }
        console.log("Dispatching user metadata related actions.");
        const token = await getToken();
        if (!token) {
          toast.error("Failed to fetch authentication token.");
          return;
        }
        dispatch(fetchUserMetadata({ token }));
      }
    })();
  }, [isAuthenticated, user, isLoaded]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoaded ? (
        isAuthenticated ? (
          children
        ) : (
          <RedirectToSignIn redirectUrl={routes.auth.login} />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};
