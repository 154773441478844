import { useDrawer } from "@cleanlist-ai/portal/hooks";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { Drawer } from "rizzui";
import { MobileDrawerNav } from "../mobile-drawer-nav.component";

export const GlobalDrawer = () => {
  const { mobileDrawer, closeDrawer } = useDrawer();
  const pathname = usePathname();

  useEffect(() => {
    closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <Drawer
      isOpen={mobileDrawer.displayMobileDrawer}
      onClose={closeDrawer}
      placement={mobileDrawer.mobileDrawerPlacement}
      customSize={mobileDrawer.mobileDrawerWidth}
      overlayClassName="dark:bg-opacity-40 dark:backdrop-blur-md"
      containerClassName="dark:bg-gray-100"
      className="z-[9999]"
    >
      {mobileDrawer?.displayMobileDrawer && (
        <MobileDrawerNav className="static w-full 2xl:w-full" />
      )}
    </Drawer>
  );
};
