import { Lead } from "@cleanlist-ai/cleanse-api-client-lib";
import { useAppSelector } from "@cleanlist-ai/portal/store";
import Link from "next/link";
import { GoDesktopDownload, GoDownload } from "react-icons/go";
import { ActionIcon, Text, Tooltip } from "rizzui";
import { AvatarCard } from "../avatar-card.component";
import BasicTableWidget from "../table/controlled-table/basic-table-widget";
import { TableHeader } from "../table/table-header.component";

type Columns = {
  sortConfig?: {
    direction: "asc" | "desc";
    key: string;
  };
  onDeleteItem: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onChecked?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};

const lastExportedProspectsColumns = ({
  sortConfig,
  // onDeleteItem,
  onHeaderCellClick,
}: Columns) => [
  {
    title: (
      <TableHeader heading="Uuid" className="ps-4 [&>div]:whitespace-nowrap" />
    ),
    dataIndex: "entityUrn",
    key: "entityUrn",
    width: 90,
    render: (value: string) => (
      <Link
        // href={routes.eCommerce.editOrder(row.id)}
        // TODO: Link to the details
        href="#"
        className="ps-4 hover:text-gray-900 hover:underline"
      >
        #{value.split(":").pop()}
      </Link>
    ),
  },
  {
    title: (
      <TableHeader
        heading="Prospect"
        sortable
        ascending={
          sortConfig?.direction === "asc" && sortConfig?.key === "fullName"
        }
      />
    ),
    dataIndex: "contact",
    key: "Contact",
    width: 400,
    hidden: "contact",
    onHeaderCell: () => onHeaderCellClick("fullName"),
    render: (_: any, row: any) => (
      <AvatarCard
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
          row.fullName ?? "N/A"
        )}`}
        name={row.fullName ?? "N/A"}
        description={`${row.currentPositions?.[0].title} @ ${row.currentPositions?.[0].companyName}`}
      />
    ),
  },
  {
    title: (
      <TableHeader
        heading="Company"
        sortable
        ascending={
          sortConfig?.direction === "asc" && sortConfig?.key === "companyName"
        }
      />
    ),
    dataIndex: "companyName",
    key: "Company",
    width: 100,
    onHeaderCell: () => onHeaderCellClick("companyName"),
    render: (_: string, row: Lead) => {
      return (
        <Link
          href={`https://www.linkedin.com/sales/company/${row.company}`}
          target="_blank"
          rel="noopener noreferrer"
          className="dark:texts-gray-600 font-medium text-gray-700 no-underline hover:underline"
        >
          {row.company}
        </Link>
      );
    },
  },
  {
    title: (
      <TableHeader
        heading="Email"
        // sortable
        // ascending={
        //   sortConfig?.direction === 'asc' && sortConfig?.key === 'tenure'
        // }
      />
    ),
    // onHeaderCell: () => onHeaderCellClick('tenure'),
    dataIndex: "emails",
    key: "Emails",
    hidden: "emails",
    width: 120,
    render: (_: string, row: Lead) => (
      <Text className="font-medium text-gray-700 dark:text-gray-600">
        {row.primaryEmail}
      </Text>
    ),
  },
  {
    // Need to avoid this issue -> <td> elements in a large <table> do not have table headers.
    title: <TableHeader heading="Operations" className="opacity-0" />,
    dataIndex: "action",
    key: "action",
    width: 130,
    render: () => (
      <div className="flex items-center justify-end gap-3 pe-4">
        <Tooltip
          size="sm"
          content={"Download (Raw)"}
          placement="top"
          color="invert"
        >
          {/* <Link href={routes.eCommerce.editOrder(row.id)}> */}
          <ActionIcon
            as="span"
            size="sm"
            variant="outline"
            aria-label={"Edit Order"}
            className="hover:text-gray-700"
          >
            <GoDownload className="h-4 w-4" />
          </ActionIcon>
          {/* </Link> */}
        </Tooltip>
        <Tooltip
          size="sm"
          content={"Download (Cleaned)"}
          placement="top"
          color="invert"
        >
          {/* <Link href={routes.eCommerce.orderDetails(row.id)}> */}
          <ActionIcon
            as="span"
            size="sm"
            variant="outline"
            aria-label={"View Order"}
            className="hover:text-gray-700"
          >
            <GoDesktopDownload className="h-4 w-4" />
          </ActionIcon>
          {/* </Link> */}
        </Tooltip>
      </div>
    ),
  },
];

type LastExportedLeadsTableProps = {
  className?: string;
};

export const LastExportedLeadsTable = ({
  className,
}: LastExportedLeadsTableProps) => {
  const { lastExtractedLeads } = useAppSelector((state) => state.leads);
  return (
    <BasicTableWidget
      title="Latest Leads"
      data={lastExtractedLeads}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getColumns={lastExportedProspectsColumns}
      className={className}
      enablePagination
      noGutter
      searchPlaceholder="Query leads..."
      variant="modern"
    />
  );
};
