import { cn } from "@cleanlist-ai/portal/utils";
import Link from "next/link";
import { Children, ReactNode, cloneElement, isValidElement } from "react";

type BreadcrumbItemProps = {
  href?: string;
  className?: string;
  children: ReactNode;
};

export const BreadcrumbItem = ({
  href = "#",
  className,
  children,
}: BreadcrumbItemProps) => {
  return (
    <Link
      href={href}
      role="button"
      className={cn("inline-flex items-center gap-2 text-sm", className)}
    >
      {children}
    </Link>
  );
};

interface BreadcrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  separator?: React.ReactNode;
  disableCurrent?: boolean;
  children: React.ReactNode;
  className?: string;
  separatorClassName?: string;
  separatorVariant?: "default" | "circle";
}

export const Breadcrumb = ({
  separator = "/",
  disableCurrent = true,
  children,
  className,
  separatorClassName,
  separatorVariant = "default",
}: BreadcrumbProps) => {
  const childrenCount = Children.count(children);

  return (
    <div className={cn("inline-flex items-center gap-2.5", className)}>
      {Children.map(children, (child, index) => {
        if (!isValidElement<BreadcrumbItemProps>(child)) return child;

        return (
          <>
            {cloneElement(child, {
              className: cn(
                "text-gray-700 last:text-gray-500 font-medium",
                disableCurrent && "last:pointer-events-none"
              ),
            })}
            {index < childrenCount - 1 &&
              (separatorVariant === "default" ? (
                <span
                  className={cn("text-sm text-gray-500", separatorClassName)}
                >
                  {separator}
                </span>
              ) : (
                <span className="h-1 w-1 rounded-full bg-gray-300" />
              ))}
          </>
        );
      })}
    </div>
  );
};

Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.displayName = "Breadcrumb";
