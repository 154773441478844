import {
  GetLeadRequest,
  GetLeadsRequest,
} from "@cleanlist-ai/cleanse-api-client-lib";
import { leadsApi } from "./config";

const fetchLatestExtractedLeads = (apiKey: string) => {
  return leadsApi.getUserLatestExtractedLeads({
    headers: {
      api_key: apiKey,
    },
  });
};

const fetchLeads = (apiKey: string, payload: GetLeadsRequest = {}) => {
  return leadsApi.getLeads(payload, {
    headers: {
      api_key: apiKey,
    },
  });
};

const fetchLead = (apiKey: string, payload: GetLeadRequest) => {
  return leadsApi.getLead(payload, {
    headers: {
      api_key: apiKey,
    },
  });
};

export const leadsProvider = {
  fetchLead,
  fetchLeads,
  fetchLatestExtractedLeads,
};
