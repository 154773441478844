export enum LANGUAGE {
  EN = "en",
  FR = "fr",
}

export enum MODE {
  DARK = "dark",
  LIGHT = "light",
}

export enum PHASE {
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
}

export type DrawerPlacements = "left" | "right" | "top" | "bottom";

export interface MobileDrawer {
  readonly displayMobileDrawer: boolean;
  readonly mobileDrawerPlacement: DrawerPlacements;
  readonly mobileDrawerWidth?: string;
}

export interface ILayoutState {
  readonly language: LANGUAGE;
  readonly mode: MODE;
  readonly phase: PHASE;
  readonly displayToolbar: boolean;
  readonly isDragging: boolean;
  readonly mobileDrawer: MobileDrawer;
}
