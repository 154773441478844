import { VerifyRecaptchaOperationRequest } from "@cleanlist-ai/cleanse-api-client-lib";
import { authApi } from "./config";

const verifyRecaptcha = (reCAPTCHARequest: VerifyRecaptchaOperationRequest) => {
  return authApi.verifyRecaptcha(reCAPTCHARequest);
};

export const authProvider = {
  verifyRecaptcha,
};
