import { SignedIn, SignedOut, useSignUp } from "@clerk/nextjs";
import { OAuthStrategy } from "@clerk/nextjs/server";
import Link from "next/link";
import { ReactNode } from "react";
import { FcGoogle } from "react-icons/fc";
import { PiAppleLogo } from "react-icons/pi";
import { Button, Text, Title } from "rizzui";
import { Divider } from "../divider.component";
import { routes } from "../routes";
import { Logo } from "./logo.component";

type AuthLayoutProps = {
  children: ReactNode;
  heading: ReactNode;
  description?: string;
  banner?: {
    heading: string;
    description: string;
  };
  media?: ReactNode;
  displayOAuthButtons?: boolean;
};

export const AuthLayout = ({
  children,
  heading,
  banner,
  description,
  media,
  displayOAuthButtons = false,
}: AuthLayoutProps) => {
  const { isLoaded, signUp } = useSignUp();
  // TODO: Not required until clerk chrome extension sdk can enable OAuth workflows
  const onPressAuthenticate = async (
    e: { preventDefault: () => void },
    strategy: OAuthStrategy
  ) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
    try {
      const res = await signUp.authenticateWithRedirect({
        redirectUrl: "/auth/sso-callback",
        redirectUrlComplete: routes.dashboard,
        strategy,
      });
      console.log(res);
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
    }
  };

  return (
    <>
      <SignedOut>
        <main>
          <div className="min-h-screen justify-between gap-x-8 px-4 py-8 pt-10 md:pt-12 lg:flex lg:p-6 xl:gap-x-10 xl:p-7 2xl:p-10 2xl:pt-10 [&>div]:min-h-[calc(100vh-80px)]">
            <div className="relative flex w-full items-center justify-center lg:w-5/12 2xl:justify-end 2xl:pe-24">
              <div className=" w-full max-w-sm md:max-w-md lg:py-7 lg:ps-3 lg:pt-16 2xl:w-[630px] 2xl:max-w-none 2xl:ps-20 2xl:pt-7">
                <div className="mb-7 px-6 pt-3 text-center md:pt-0 lg:px-0 lg:text-start xl:mb-8 2xl:mb-10">
                  <Link
                    href={"/"}
                    className="mb-6 inline-flex max-w-[168px] xl:mb-8"
                  >
                    <Logo />
                  </Link>
                  <Title
                    as="h2"
                    className="mb-5 text-[26px] leading-snug md:text-3xl md:!leading-normal lg:mb-7 lg:pe-16 lg:text-[28px] xl:text-3xl 2xl:pe-8 2xl:text-4xl"
                  >
                    {heading}
                  </Title>
                  <Text className=" leading-[1.85] text-gray-700 md:leading-loose lg:pe-8 2xl:pe-14">
                    {description}
                  </Text>
                </div>
                {displayOAuthButtons && (
                  <>
                    <div className="grid grid-cols-1 gap-4 pb-5 md:grid-cols-2 md:pb-6 xl:gap-5 xl:pb-7">
                      <Button
                        onClick={(e) => onPressAuthenticate(e, "oauth_apple")}
                        className="h-11 w-full"
                      >
                        <PiAppleLogo className="me-2 h-4 w-4 shrink-0" />
                        <span className="truncate">Login with Apple</span>
                      </Button>
                      <Button
                        variant="outline"
                        onClick={(e) => onPressAuthenticate(e, "oauth_google")}
                        className="h-11 w-full"
                      >
                        <FcGoogle className="me-2 h-4 w-4 shrink-0" />
                        <span className="truncate">Login with Google</span>
                      </Button>
                    </div>
                    <Divider heading="OR" className="mb-5 2xl:mb-7" isCenter />
                  </>
                )}
                {children}
              </div>
            </div>
            <div className="hidden w-7/12 items-center justify-center rounded-[20px] bg-gray-50 px-6 dark:bg-gray-100/40 lg:flex xl:justify-start 2xl:px-16">
              <div className="pb-8 pt-10 text-center xl:pt-16 2xl:block 2xl:w-[1063px]">
                <div className="mx-auto mb-10 max-w-sm pt-2 2xl:max-w-lg">
                  <Title
                    as="h2"
                    className="mb-5 font-semibold !leading-normal lg:text-[26px] 2xl:px-10 2xl:text-[32px]"
                  >
                    {banner?.heading}
                  </Title>
                  <Text className="leading-[1.85] text-gray-700 md:leading-loose 2xl:px-6">
                    {banner?.description}
                  </Text>
                </div>
                {media}
              </div>
            </div>
          </div>
        </main>
      </SignedOut>
      <SignedIn>{children}</SignedIn>
    </>
  );
};
