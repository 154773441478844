import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuthState } from "./types";

const initialState: IAuthState = {
  registrationEmailDispatched: false,
  passwordReset: false,
  loading: false,
};

export const authSlice = createSlice({
  name: "@@auth",
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.passwordReset = false;
      state.registrationEmailDispatched = false;
      state.loading = false;
    },
    setResetPassword: (state, action: PayloadAction<boolean>) => {
      state.passwordReset = action.payload;
    },
  },
});

export const { resetAuthState, setResetPassword } = authSlice.actions;

export const authReducer = authSlice.reducer;
