import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { PiCommand, PiMagnifyingGlassBold } from 'react-icons/pi';
import { Modal, cn } from 'rizzui';
import { QueryInput } from './query-input.component';

type QueryWidgetProps = {
  className?: string;
};

export const QueryWidget = ({ className }: QueryWidgetProps) => {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        setOpen(!open);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [open]);

  useEffect(() => {
    // Closing if the route changes
    setOpen(() => false);
  }, [pathname]);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className={cn(
          'group inline-flex items-center focus:outline-none active:translate-y-px xl:h-10 xl:w-full xl:max-w-sm xl:rounded-xl xl:border xl:border-gray-200 xl:py-2 xl:pe-2 xl:ps-3.5 xl:shadow-sm xl:backdrop-blur-md xl:transition-colors xl:duration-200 xl:hover:border-gray-900 xl:hover:outline-double xl:hover:outline-[0.5px] xl:hover:outline-gray-900 xl:focus-visible:border-gray-900 xl:focus-visible:outline-double xl:focus-visible:outline-[0.5px] xl:focus-visible:outline-gray-900',
          className
        )}
      >
        <PiMagnifyingGlassBold className="me-2 h-[18px] w-[18px]" />
        <span className="hidden text-sm text-gray-600 group-hover:text-gray-900 xl:inline-flex">
          Type to get started...
        </span>
        <span className="search-command ms-auto hidden items-center text-sm text-gray-600 lg:flex lg:rounded-md lg:bg-gray-200/70 lg:px-1.5 lg:py-1 lg:text-xs lg:font-semibold xl:justify-normal">
          <PiCommand strokeWidth={1.3} className="h-[15px] w-[15px]" />K
        </span>
      </button>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        overlayClassName="dark:bg-opacity-20"
        containerClassName="dark:bg-gray-100/90 overflow-hidden"
      >
        <QueryInput onClose={() => setOpen(false)} />
      </Modal>
    </>
  );
};
