import { resetUserState, useAppDispatch } from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import { useClerk, useUser } from "@clerk/nextjs";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Avatar, Button, Popover, Text, Title } from "rizzui";
import { dropdownMenuEntries } from "./menu";

const DropdownMenu = () => {
  const dispatch = useAppDispatch();
  const { user } = useUser();
  const { signOut } = useClerk();
  const router = useRouter();

  return (
    <div className="w-64 text-left rtl:text-right">
      <div className="flex items-center border-b border-gray-300 px-6 pb-5 pt-6">
        <Avatar
          src={
            user?.hasImage
              ? user?.imageUrl
              : `https://ui-avatars.com/api/?name=${user?.fullName}`
          }
          name={user?.fullName || "N/A"}
        />
        <div className="ms-3">
          <Title as="h6" className="font-semibold">
            {user?.fullName}
          </Title>
          <Text className="text-gray-600">
            {user?.primaryEmailAddress?.emailAddress ?? user?.username}
          </Text>
        </div>
      </div>
      <div className="grid px-3.5 py-3.5 font-medium text-gray-700">
        {dropdownMenuEntries.map((item) => (
          <Link
            key={item.name}
            href={item.href ?? item.link ?? "#"}
            target={item.href ? "_blank" : undefined}
            rel={item.href ? "noopener noreferrer" : undefined}
            className="group my-0.5 flex items-center rounded-md px-2.5 py-2 hover:bg-gray-100 focus:outline-none hover:dark:bg-gray-50/50"
          >
            {item.name}
          </Link>
        ))}
      </div>
      <div className="border-t border-gray-300 px-6 pb-6 pt-5">
        <Button
          className="h-auto w-full justify-start p-0 font-medium text-gray-700 outline-none focus-within:text-gray-600 hover:text-gray-900 focus-visible:ring-0"
          variant="text"
          onClick={() =>
            signOut(() => {
              dispatch(resetUserState());
              router.replace("/auth/login");
            })
          }
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

type MenuProps = {
  buttonClassName?: string;
  avatarClassName?: string;
};

export const Menu = ({ buttonClassName, avatarClassName }: MenuProps) => {
  const { user } = useUser();
  const [displayPopover, setDisplayPopover] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setDisplayPopover(false);
  }, [pathname]);

  return (
    <Popover
      isOpen={displayPopover}
      setIsOpen={setDisplayPopover}
      shadow="sm"
      placement="bottom-end"
    >
      <Popover.Trigger>
        <button
          className={cn(
            "w-9 shrink-0 rounded-full outline-none focus-visible:ring-[1.5px] focus-visible:ring-gray-400 focus-visible:ring-offset-2 active:translate-y-px sm:w-10",
            buttonClassName
          )}
        >
          <Avatar
            src={
              user?.hasImage
                ? user?.imageUrl
                : `https://ui-avatars.com/api/?name=${user?.fullName}`
            }
            name={user?.firstName || "N/A"}
            className={cn("!h-9 w-9 sm:!h-10 sm:w-10", avatarClassName)}
          />
        </button>
      </Popover.Trigger>

      <Popover.Content className="z-[9999] p-0 dark:bg-gray-100 [&>svg]:dark:fill-gray-100">
        <DropdownMenu />
      </Popover.Content>
    </Popover>
  );
};
