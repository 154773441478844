import {
  CreateApiKeyOperationRequest,
  GetApiKeyLimitsRequest,
} from "@cleanlist-ai/cleanse-api-client-lib";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userProvider } from "../api/user";
import { WebtokenPayload } from "../auth/types";
import { IUserState, UserErrorCodesEnum } from "./types";

const initialState: IUserState = {
  // API and Credit Packages
  creditLimits: null,
  limits: null,
  apiKey: null,
  apiKeys: [],
  loading: false,
  error: null,
};

export const fetchUserUsageCredits = createAsyncThunk(
  "@@user/fetchUserUsageCredits",
  async (payload: WebtokenPayload, { rejectWithValue }) => {
    try {
      return userProvider.fetchUserUsageCredits(payload.token);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchUserApiKeyLimits = createAsyncThunk(
  "@@user/fetchUserApiKeyLimits",
  async (
    payload: WebtokenPayload & GetApiKeyLimitsRequest,
    { rejectWithValue }
  ) => {
    try {
      const { token, ...apiKeyLimitsRequestPayload } = payload;
      return userProvider.fetchUserApiKeyLimits(
        token,
        apiKeyLimitsRequestPayload
      );
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const createUserApiKey = createAsyncThunk(
  "@@user/createUserApiKey",
  async (
    payload: WebtokenPayload & CreateApiKeyOperationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { token, ...createUserApiKeyPayload } = payload;
      return userProvider.createUserApiKey(token, createUserApiKeyPayload);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchUserApiKeys = createAsyncThunk(
  "@@user/fetchUserApiKeys",
  async (payload: WebtokenPayload, { rejectWithValue }) => {
    try {
      return userProvider.fetchUserApiKeys(payload.token);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

export const fetchUserMetadata = createAsyncThunk(
  "@@user/fetchUserMetadata",
  async (payload: WebtokenPayload, { rejectWithValue }) => {
    try {
      return userProvider.fetchUser(payload.token);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  }
);

const userSlice = createSlice({
  name: "@@user",
  initialState,
  reducers: {
    resetUserState: (state) => {
      state.limits = undefined;
      state.apiKey = null;
      state.apiKeys = [];
      state.creditLimits = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMetadata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.FETCH_USER_METADATA_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchUserMetadata.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(createUserApiKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUserApiKey.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.CREATE_USER_API_KEY_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(createUserApiKey.fulfilled, (state, action) => {
        state.apiKey = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchUserApiKeys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserApiKeys.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.FETCH_USER_API_KEYS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchUserApiKeys.fulfilled, (state, action) => {
        state.apiKeys = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchUserApiKeyLimits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserApiKeyLimits.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.FETCH_USER_API_KEY_LIMITS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchUserApiKeyLimits.fulfilled, (state, action) => {
        state.limits = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchUserUsageCredits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserUsageCredits.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.FETCH_CREDS_PACKAGE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchUserUsageCredits.fulfilled, (state, action) => {
        state.creditLimits = action.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { resetUserState } = userSlice.actions;
export const userReducer = userSlice.reducer;
