import { useEffect, useRef } from "react";

export function useCarousel() {
  const carouselEl = useRef<HTMLDivElement>(null!);
  const carouselPrevBtn = useRef<HTMLButtonElement>(null!);
  const carouselNextBtn = useRef<HTMLButtonElement>(null!);

  function scrollToTheRight() {
    const offsetWidth = carouselEl.current.offsetWidth;
    carouselEl.current.scrollLeft += offsetWidth / 2;
    carouselPrevBtn.current.classList.remove("opacity-0", "invisible");
  }

  function scrollToTheLeft() {
    const offsetWidth = carouselEl.current.offsetWidth;
    carouselEl.current.scrollLeft -= offsetWidth / 2;
    carouselNextBtn.current.classList.remove("opacity-0", "invisible");
  }

  useEffect(() => {
    const filterBarEl = carouselEl.current;
    const prevBtn = carouselPrevBtn.current;
    const nextBtn = carouselNextBtn.current;
    const formPageHeaderEl = filterBarEl.classList.contains(
      "formPageHeadercarouselElJS"
    );
    initNextPrevBtnVisibility();

    function initNextPrevBtnVisibility() {
      const offsetWidth = filterBarEl.offsetWidth;
      const scrollWidth = filterBarEl.scrollWidth;
      // show next btn when scrollWidth is gather than offsetWidth
      if (scrollWidth > offsetWidth) {
        nextBtn?.classList.remove("opacity-0", "invisible");
        if (formPageHeaderEl) {
          filterBarEl?.classList.add("!-mb-[43px]");
        }
      } else {
        nextBtn?.classList.add("opacity-0", "invisible");
        if (formPageHeaderEl) {
          filterBarEl?.classList.remove("!-mb-[43px]");
        }
      }
      // hide prev btn initially
      prevBtn?.classList.add("opacity-0", "invisible");
    }

    function visibleNextAndPrevBtnOnScroll() {
      const newScrollLeft = filterBarEl?.scrollLeft,
        offsetWidth = filterBarEl?.offsetWidth,
        scrollWidth = filterBarEl?.scrollWidth;
      // reach to the right end
      if (scrollWidth - newScrollLeft == offsetWidth) {
        nextBtn?.classList.add("opacity-0", "invisible");
        prevBtn?.classList.remove("opacity-0", "invisible");
      } else {
        nextBtn?.classList.remove("opacity-0", "invisible");
      }
      // reach to the left end
      if (newScrollLeft === 0) {
        prevBtn?.classList.add("opacity-0", "invisible");
        nextBtn?.classList.remove("opacity-0", "invisible");
      } else {
        prevBtn?.classList.remove("opacity-0", "invisible");
      }
    }

    window.addEventListener("resize", initNextPrevBtnVisibility);
    filterBarEl.addEventListener("scroll", visibleNextAndPrevBtnOnScroll);
    // clear event
    return () => {
      window.removeEventListener("resize", initNextPrevBtnVisibility);
      filterBarEl.removeEventListener("scroll", visibleNextAndPrevBtnOnScroll);
    };
  }, []);

  return {
    carouselEl,
    carouselPrevBtn,
    carouselNextBtn,
    scrollToTheRight,
    scrollToTheLeft,
  };
}
