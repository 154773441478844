import { POSTHOG_FLAGS } from "@cleanlist-ai/portal/constants";
import { authProvider } from "@cleanlist-ai/portal/store";
import { SignedOut, useAuth, useSignUp, useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiPencil } from "react-icons/pi";
import { Button, Checkbox, Input, Password, Text } from "rizzui";
import * as yup from "yup";
import { Loader } from "../loader.component";
import { routes } from "../routes";

const RECAPTCHA_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_KEY ??
  "6LcvEnopAAAAAC2HKmtsFwzrGkFESSm6IDUxvMvk";

const registerSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .min(12, "Password must be at least 12 characters")
      .max(32, "Password must be at most 32 characters")
      .required("Password is required"),
    tos: yup
      .bool()
      .required()
      .oneOf([true], "You must accept the terms and conditions"),
    recaptcha: yup.string().required(),
  })
  .required();

type RegisterFormValues = yup.InferType<typeof registerSchema>;

type ClerkAuthError = {
  errors: {
    code: string;
    message: string;
    longMessage: string;
  }[];
};

export const RegisterForm = () => {
  const router = useRouter();
  const registerFlag = useFeatureFlagEnabled(POSTHOG_FLAGS.REGISTER_FLAG);
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoaded, signUp, setActive } = useSignUp();
  const { isSignedIn: isAuthenticated } = useAuth();
  const [pendingVerfication, setPendingVerfication] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [code, setCode] = useState("");
  const { user } = useUser();

  const {
    register: registerField,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormValues>({ resolver: yupResolver(registerSchema) });

  const { ref: passwordRef, ...registerPassword } = registerField("password");
  const { ref: emailRef, ...registerEmail } = registerField("email");
  const { ref: firstNameRef, ...registerFirstName } =
    registerField("firstName");
  const { ref: lastNameRef, ...registerLastName } = registerField("lastName");
  const { ref: tosRef, ...registerTos } = registerField("tos");

  const dispatchVerificationEmail = async () => {
    if (!count) {
      setCount(59);
      return signUp?.prepareEmailAddressVerification({
        strategy: "email_code",
      });
    }
  };

  const onFormSubmit: SubmitHandler<RegisterFormValues> = async ({
    firstName,
    lastName,
    email,
    password,
    recaptcha,
  }) => {
    try {
      if (!registerFlag) {
        toast.error(
          "Registration is not enabled currently. Please check back later!"
        );
        return;
      }
      setLoading(true);
      const res = await authProvider.verifyRecaptcha({
        verifyRecaptchaRequest: {
          recaptchaResponse: recaptcha,
        },
      });
      if (!res.success) {
        toast.error("ReCAPTCHA failed. Please try again!");
        setLoading(false);
        return;
      }
      await signUp?.create({
        emailAddress: email,
        password,
        firstName,
        lastName,
      });
      await dispatchVerificationEmail();
      setPendingVerfication(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error((err as ClerkAuthError).errors[0].longMessage);
      toast.error((err as ClerkAuthError).errors[0].longMessage);
    }
  };
  // This verifies the user using email code that is delivered.
  const onPressVerify = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    try {
      setLoading(true);
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
      if (completeSignUp.status !== "complete") {
        toast.error("Failed to verify email. Please try again!");
      }
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
      }
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      toast.error((err as ClerkAuthError).errors[0].longMessage);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      toast.success(`Greetings ${user?.fullName}`);
      if (!user?.unsafeMetadata.completedOnboarding) {
        return router.replace("/auth/onboarding");
      }
      return router.replace(routes.auth.onboarding);
    }
  }, [isAuthenticated, router, user]);

  // Used to ensure that the verification email is only sent every 60 seconds
  useEffect(() => {
    //Implementing the setInterval method to decrease the count by 1 every second
    const interval = setInterval(() => {
      if (count) {
        setCount(count - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  });

  return (
    <SignedOut>
      {!pendingVerfication && !loading && (
        <>
          <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
            <div className="space-y-5">
              <Input
                type="text"
                size="lg"
                label="First Name"
                placeholder="Enter First name"
                className="[&>label>span]:font-medium"
                color="info"
                inputClassName="text-sm"
                ref={firstNameRef}
                {...registerFirstName}
                error={errors.firstName?.message}
              />
              <Input
                type="text"
                size="lg"
                label="Last Name"
                placeholder="Enter Last name"
                className="[&>label>span]:font-medium"
                color="info"
                inputClassName="text-sm"
                ref={lastNameRef}
                {...registerLastName}
                error={errors.lastName?.message}
              />
              <Input
                type="email"
                size="lg"
                label="Email"
                placeholder="Enter email"
                color="info"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                ref={emailRef}
                {...registerEmail}
                error={errors.email?.message}
              />
              <Password
                label="Password"
                placeholder="Enter password"
                size="lg"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                color="info"
                ref={passwordRef}
                {...registerPassword}
                error={errors.password?.message}
              />
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => value && setValue("recaptcha", value)}
              />
              {errors.recaptcha && (
                <Text className="text-sm text-red-500">
                  Please complete the recaptcha
                </Text>
              )}
              <div className="flex items-center justify-between pb-2">
                <Checkbox
                  ref={tosRef}
                  {...registerTos}
                  className="[&>label>span]:font-medium [&>label]:items-start"
                  label={
                    <>
                      By registering, I comply with Cleanlist&apos;s{" "}
                      <Link
                        href="/"
                        className="font-medium text-blue transition-colors hover:underline"
                      >
                        terms
                      </Link>{" "}
                      &{" "}
                      <Link
                        href="/"
                        className="font-medium text-blue transition-colors hover:underline"
                      >
                        privacy policy
                      </Link>
                    </>
                  }
                />
                {/* When tos is not checked when user submits show a warning*/}
              </div>
              {errors.tos && (
                <Text className="text-sm text-red-500">
                  You must agree to the terms and privacy policy.
                </Text>
              )}
              <Button className="w-full" type="submit" size="lg">
                <span>{loading ? <Loader /> : "Register"}</span>{" "}
                <PiPencil className="ms-2 mt-0.5 h-6 w-6" />
              </Button>
            </div>
          </form>
          <Text className="mt-6 text-center leading-loose text-gray-500 lg:mt-8 lg:text-start">
            Part of Cleanlist?{" "}
            <Link
              href={routes.auth.login}
              className="font-semibold text-gray-700 transition-colors hover:text-blue"
            >
              Login
            </Link>
          </Text>
        </>
      )}
      {pendingVerfication && !loading && (
        <div>
          <form className="flex flex-col items-center">
            <Input
              label="Enter verification code"
              placeholder="Code..."
              size="lg"
              className="[&>label>span]:font-medium"
              inputClassName="text-sm"
              color="info"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <div>
              <Button className="m-5" onClick={onPressVerify}>
                Verify Email
              </Button>
              <Button
                className="m-5"
                onClick={dispatchVerificationEmail}
                disabled={!!count}
              >
                {!count ? "Re-send email" : `Re-send email in ${count} seconds`}
              </Button>
            </div>
          </form>
        </div>
      )}
      {loading && <Loader />}
    </SignedOut>
  );
};
