import { cn } from "@cleanlist-ai/portal/utils";
import { PiArrowClockwise } from "react-icons/pi";
import { Button } from "rizzui";

type TableExportButtonProps = {
  onClick: () => void;
  className?: string;
};

export const TableRefreshButton = ({
  onClick,
  className,
}: TableExportButtonProps) => {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      className={cn("w-full @lg:w-auto", className)}
    >
      <PiArrowClockwise className="me-1.5 h-[17px] w-[17px]" />
      Refresh
    </Button>
  );
};
