import { portalConfig } from "@cleanlist-ai/portal/config";
import { cn } from "@cleanlist-ai/portal/utils";
import Image from "next/image";
import Link from "next/link";
import { useMedia } from "react-use";
import { Button } from "rizzui";
import { routes } from "../routes";

type OnboardingHeaderProps = {
  className?: string;
};

export const OnboardingHeader = ({ className }: OnboardingHeaderProps) => {
  const isMobile = useMedia("(max-width: 767px)", false);

  return (
    <header
      className={cn(
        "flex w-full items-center justify-between px-4 py-5 md:h-20 md:px-5 lg:px-8 4xl:px-10",
        className
      )}
    >
      <Link href="/">
        <Image
          src={isMobile ? portalConfig.icon : portalConfig.logo}
          alt={portalConfig.title}
          className="h-10 w-auto"
          // className="invert"
          priority
        />
      </Link>
      <div className="flex items-center gap-2">
        <Button
          variant="text"
          className="text-white hover:enabled:text-white"
          onClick={() => window.open(routes.external.docs, "_blank")}
        >
          Questions?
        </Button>
        {/* <Button
          rounded="pill"
          variant="outline"
          className="gap-2 whitespace-nowrap text-white hover:enabled:border-white dark:border-gray-800 dark:hover:enabled:border-white"
        >
          <FiSave className="h-4 w-4" />
          Persist
        </Button> */}
      </div>
    </header>
  );
};
