import { useCallback, useState } from "react";
import {
  useEventListener,
  useIsomorphicLayoutEffect,
} from "./use-event-listener.hook";

type Dimension = {
  width: number;
  height: number;
};

export function useElementDimensions<
  T extends HTMLElement = HTMLDivElement,
>(): [(node: T | null) => void, Dimension] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [dimension, setDimensions] = useState<Dimension>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setDimensions({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener("resize", handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, dimension];
}
