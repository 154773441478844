import { MODE, useAppSelector } from "@cleanlist-ai/portal/store";
import Image from "next/image";
import DarkLogo from "../assets/logos/dark-logo.svg";
import LogoIcon from "../assets/logos/icon.png";
import LightLogo from "../assets/logos/light-logo.svg";

type LogoProps = {
  className?: string;
  icon?: boolean;
};

export const Logo = ({ className, icon }: LogoProps) => {
  const { mode } = useAppSelector((state) => state.layout);
  return (
    <Image
      src={icon ? LogoIcon : mode === MODE.LIGHT ? DarkLogo : LightLogo}
      alt="logo"
      className={className}
    />
  );
};
