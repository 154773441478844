import { usePhases } from "@cleanlist-ai/portal/store";
import { useOrganization, useOrganizationList } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { PiPlusBold, PiTrashBold } from "react-icons/pi";
import { Button, Input, Select, Text } from "rizzui";
import * as yup from "yup";
import { PhasesBreadcrumb } from "./phases-breadcrumb.component";

const organizationSchema = yup.object({
  name: yup.string().required("Please provide a organization name."),
  members: yup
    .array()
    .of(
      yup.object({
        email: yup
          .string()
          .email("Please enter a proper email.")
          .required("Email is required."),
        role: yup.string().oneOf(["Sudo", "Member"]).required(),
      })
    )
    .default([]),
});

type OrganizationFormValues = yup.InferType<typeof organizationSchema>;

const roleOptions = [
  {
    value: "Member",
    label: "Member",
  },
  {
    value: "Sudo",
    label: "Sudo",
  },
];

export const PhaseFour = () => {
  const { phase, gotoNextPhase } = usePhases();
  const { createOrganization, setActive } = useOrganizationList();
  const { organization } = useOrganization({
    invitations: {},
  });

  const {
    control,
    register: registerField,
    formState: { errors },
    handleSubmit,
  } = useForm<OrganizationFormValues>({
    resolver: yupResolver(organizationSchema),
    defaultValues: {
      name: "",
      members: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "members",
  });

  const { ref: nameRef, ...registerName } = registerField("name");

  useEffect(() => {
    if (errors.name || errors.members) {
      toast.error(
        errors.name?.message ||
          errors.members?.message ||
          "Problem occurred. Please try again!"
      );
    }
  }, [errors]);

  const onFormSubmit: SubmitHandler<OrganizationFormValues> = async ({
    name,
    members,
  }) => {
    try {
      const orgToastUid = toast.loading("Creating organization...");
      const org = await createOrganization?.({ name });
      if (!org) {
        toast.error("Failed to create organization", { id: orgToastUid });
        return;
      }
      toast.success("Organization has been created!", { id: orgToastUid });
      console.log("Org", org.id);
      setActive?.({
        organization: org.id,
      });

      // Ensure organization is set before proceeding
      const waitForOrganization = async () => {
        while (!organization) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // Re-fetch or re-check the organization state here if necessary
        }
      };

      await waitForOrganization();
      console.log(organization);

      const assignOrgMembers = async () => {
        return await Promise.all(
          members.map((member: { email: string; role: string }) => {
            return organization?.inviteMember({
              emailAddress: member.email,
              role: member.role === "Sudo" ? "admin" : "org:member",
            });
          })
        );
      };
      const orgPendingMembers = await assignOrgMembers();

      orgPendingMembers.forEach((member) => {
        toast.success(
          `Dispatched org registration email to ${member?.emailAddress}`
        );
      });

      gotoNextPhase();
    } catch (error) {
      console.error(error);
      toast.error("Problem occurred. Please try again!");
    }
  };

  return (
    <>
      <div className="col-span-full flex flex-col justify-center @5xl:col-span-5">
        <PhasesBreadcrumb
          className="@7xl:me-10"
          heading="Organization Configuration"
          description="Configure your organization's name and members."
        />
      </div>
      <div className="col-span-full flex items-center justify-center @5xl:col-span-7">
        <form
          id={`rhf-${phase.toString()}`}
          onSubmit={handleSubmit(onFormSubmit)}
          className="grid flex-grow gap-6 rounded-lg bg-white p-5 @4xl:p-7 dark:bg-gray-0"
        >
          <div className="space-y-5">
            <Input
              type="text"
              size="lg"
              label="Name"
              labelClassName="font-semibold text-gray-900"
              placeholder="Cleanlist.ai"
              className="[&>label>span]:font-medium"
              inputClassName="text-sm"
              ref={nameRef}
              {...registerName}
              error={errors.name?.message}
            />
          </div>
          <div className="grid gap-4">
            <Text className="text-gray-900">Members</Text>
            {fields.map((field, index) => {
              return (
                <div
                  className="mb-8 grid grid-cols-1 items-start rounded-lg border border-muted p-4 shadow @md:p-5 @xl:p-6"
                  key={field.id}
                >
                  <div className="grid w-full items-start gap-3 @md:grid-cols-2 @lg:gap-4 @xl:grid-cols-3 @2xl:gap-5 @4xl:grid-cols-4">
                    <Input
                      type="email"
                      label="Email"
                      labelClassName="font-semibold text-gray-900"
                      placeholder="sal@cleanlist.ai"
                      className="@md:col-span-3 @xl:col-span-4 @2xl:col-span-2 @4xl:col-span-3 [&>label>span]:font-medium"
                      inputClassName="text-sm"
                      {...registerField(`members.${index}.email`)}
                      error={errors?.members?.[index]?.email?.message}
                    />
                    <Controller
                      name={`members.${index}.role`}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <Select
                          dropdownClassName="!z-10"
                          className="@xl:col-span-2 @2xl:col-span-1"
                          inPortal={false}
                          options={roleOptions}
                          value={value}
                          onChange={onChange}
                          // defaultValue={field.role ?? value}
                          name={name}
                          label="Role"
                          error={errors?.members?.[index]?.role?.message}
                          getOptionValue={(option) => option.value}
                        />
                      )}
                    />
                  </div>
                  <Button
                    variant="text"
                    color="danger"
                    onClick={() => remove(index)}
                    className="-mx-2 -mb-1 ms-auto mt-5 h-auto px-2 py-1 font-semibold"
                  >
                    <PiTrashBold className="me-1 h-[18px] w-[18px]" /> Remove
                  </Button>
                </div>
              );
            })}
            <div className="flex w-full flex-col items-start justify-between @4xl:flex-row @4xl:pt-6">
              <Button
                variant="flat"
                onClick={() => append({ email: "", role: "Member" })}
                className="-mt-2 mb-7 w-full @4xl:mb-0 @4xl:mt-0 @4xl:w-auto"
              >
                <PiPlusBold className="me-1.5 h-4 w-4" /> Create
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
