"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { routes } from "../../components/lib/routes";

type NavigationKey = keyof typeof navigations;

const navigations = {
  "1": "/app",
  "2": routes.app.lists,
  "3": routes.app.leads,
  "4": routes.app.prospect,
  "5": routes.app.cleaner,
  "6": routes.app.companies,
};

const allowedNumKeys = Object.keys(navigations);

export const useNavigationKeybinds = () => {
  const router = useRouter();
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        allowedNumKeys.includes(event.key)
      ) {
        event.preventDefault();
        router.push(navigations[event.key as NavigationKey]);
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
