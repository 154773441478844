import {
  CleanedProspect,
  Prospect,
  ProspectCleaningOption,
} from "@cleanlist-ai/cleanse-api-client-lib";

export enum CleanerErrorCodesEnum {
  CLEAN_LEADS_FAILED = "CLEAN_LEADS_FAILED",
  GET_CLEANING_OPTIONS_FAILED = "GET_CLEANING_OPTIONS_FAILED",
}

export interface ICleanerState {
  readonly originalProspects: Prospect[];
  readonly cleanedProspects: CleanedProspect[];
  readonly cleaningOptions: ProspectCleaningOption[];
  readonly loading: boolean;
  readonly error: {
    code: CleanerErrorCodesEnum;
    message: string;
  } | null;
}
