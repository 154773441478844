import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ILayoutState, LANGUAGE, MODE, MobileDrawer, PHASE } from "./types";

const initialState: ILayoutState = {
  isDragging: false,
  mode: MODE.LIGHT,
  phase: PHASE.ONE,
  language: LANGUAGE.EN,
  displayToolbar: false,
  mobileDrawer: {
    displayMobileDrawer: false,
    mobileDrawerPlacement: "right",
    mobileDrawerWidth: "320px",
  },
};

export const layoutSlice = createSlice({
  name: "@@layout",
  initialState,
  reducers: {
    toggleLanguage: (state) => {
      if (state.language === LANGUAGE.EN) {
        state.language = LANGUAGE.FR;
      } else {
        state.language = LANGUAGE.EN;
      }
    },
    setThemeMode: (state, action: PayloadAction<MODE>) => {
      state.mode = action.payload;
    },
    setPhase: (state, action: PayloadAction<PHASE>) => {
      state.phase = action.payload;
    },
    toggleDisplayToolbar: (state) => {
      state.displayToolbar = !state.displayToolbar;
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    setMobileDrawer: (state, action: PayloadAction<MobileDrawer>) => {
      state.mobileDrawer = action.payload;
    },
  },
});

export const {
  setPhase,
  setThemeMode,
  setIsDragging,
  toggleLanguage,
  setMobileDrawer,
  toggleDisplayToolbar,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
