import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import { RefObject, useState } from "react";
import { PiCheck } from "react-icons/pi";
import { useMedia } from "react-use";
import { Badge, Popover, Title } from "rizzui";
import SimpleBar from "simplebar-react";
import { CloudTaskSvg } from "../../svg";

dayjs.extend(relativeTime);

const mocks = [
  {
    id: 1,
    name: "Created account",
    icon: <CloudTaskSvg />,
    unRead: true,
    sendTime: "2023-06-01T09:35:31.820Z",
  },
  {
    id: 2,
    name: "Logged on",
    icon: <CloudTaskSvg />,
    unRead: true,
    sendTime: "2023-05-30T09:35:31.820Z",
  },
];

type NotificationsListProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationsList = ({ setIsOpen }: NotificationsListProps) => {
  return (
    <div className="w-[320px] text-left sm:w-[360px] 2xl:w-[420px] rtl:text-right">
      <div className="mb-3 flex items-center justify-between ps-6">
        <Title as="h5">Notifications</Title>
      </div>
      <SimpleBar className="max-h-[420px]">
        <div className="grid cursor-pointer grid-cols-1 gap-1 ps-4">
          {mocks.map((entry) => (
            <div
              key={entry.name + entry.id}
              className="group grid grid-cols-[auto_minmax(0,1fr)] gap-3 rounded-md px-2 py-2 pe-3 transition-colors hover:bg-gray-100 dark:hover:bg-gray-50"
            >
              <div className="flex h-9 w-9 items-center justify-center rounded bg-gray-100/70 p-1 dark:bg-gray-50/50 [&>svg]:h-auto [&>svg]:w-5">
                {entry.icon}
              </div>
              <div className="grid grid-cols-[minmax(0,1fr)_auto] items-center">
                <div className="w-full">
                  <Title
                    as="h6"
                    className="mb-0.5 w-11/12 truncate text-sm font-semibold"
                  >
                    {entry.name}
                  </Title>
                  <span className="ms-auto whitespace-nowrap pe-8 text-xs text-gray-500">
                    {dayjs(entry.sendTime).fromNow(true)}
                  </span>
                </div>
                <div className="ms-auto flex-shrink-0">
                  {entry.unRead ? (
                    <Badge
                      renderAsDot
                      size="lg"
                      color="primary"
                      className="scale-90"
                    />
                  ) : (
                    <span className="inline-block rounded-full bg-gray-100 p-0.5 dark:bg-gray-50">
                      <PiCheck className="h-auto w-[9px]" />
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SimpleBar>
      <Link
        href="#"
        onClick={() => setIsOpen(false)}
        className="-me-6 block px-6 pb-0.5 pt-3 text-center hover:underline"
      >
        More
      </Link>
    </div>
  );
};

type NotificationDropdownProps = {
  children: JSX.Element & { ref?: RefObject<unknown> };
};

export const NotificationDropdown = ({
  children,
}: NotificationDropdownProps) => {
  const isMobile = useMedia("(max-width: 480px)", false);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      shadow="sm"
      placement={isMobile ? "bottom" : "bottom-end"}
    >
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content className="z-[9999] px-0 pb-4 pe-6 pt-5 dark:bg-gray-100 [&>svg]:hidden [&>svg]:dark:fill-gray-100 sm:[&>svg]:inline-flex">
        <NotificationsList setIsOpen={setIsOpen} />
      </Popover.Content>
    </Popover>
  );
};
