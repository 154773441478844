import {
  AuthApi,
  CRMApi,
  CleanerApi,
  Configuration,
  DefaultApi,
  EnrichmentApi,
  LeadsApi,
  ProspectsApi,
  UserApi,
} from "@cleanlist-ai/cleanse-api-client-lib";

export const baseConfig = new Configuration({
  basePath:
    process.env.NEXT_PUBLIC_CLEANSE_API_BASE_PATH ??
    "https://api.cleanlist.ai/api/v1",
  // basePath: "http://localhost:9000/api/v1",
});

export const cleanerApi = new CleanerApi(baseConfig);
export const defaultApi = new DefaultApi(baseConfig);
export const userApi = new UserApi(baseConfig);
export const prospectsApi = new ProspectsApi(baseConfig);
export const leadsApi = new LeadsApi(baseConfig);
export const crmApi = new CRMApi(baseConfig);
export const enrichmentApi = new EnrichmentApi(baseConfig);
export const authApi = new AuthApi(baseConfig);
