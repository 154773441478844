import {
  UserErrorCodesEnum,
  createUserApiKey,
  useAppDispatch,
  useAppSelector,
  usePhases,
} from "@cleanlist-ai/portal/store";
import { useAuth } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Input } from "rizzui";
import * as yup from "yup";
import { PhasesBreadcrumb } from "./phases-breadcrumb.component";

const apiKeySchema = yup.object({
  name: yup.string().required("Please provide a name for the API key."),
});

type ApiKeyFormValues = yup.InferType<typeof apiKeySchema>;

export const PhaseFive = () => {
  const dispatch = useAppDispatch();
  const { phase, gotoNextPhase } = usePhases();
  const { apiKey, error } = useAppSelector((state) => state.user);
  const { getToken } = useAuth();

  useEffect(() => {
    if (error?.code === UserErrorCodesEnum.CREATE_USER_API_KEY_FAILED) {
      toast.error(error.message);
    }
  }, [error]);

  const {
    register: registerField,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(apiKeySchema),
    defaultValues: {
      name: undefined,
    },
  });

  const { ref: nameRef, ...registerName } = registerField("name");

  const onFormSubmit: SubmitHandler<ApiKeyFormValues> = async ({ name }) => {
    console.log(name);
    const token = await getToken();
    if (!token) {
      toast.error("Failed to fetch authentication token.");
      return;
    }
    dispatch(
      createUserApiKey({
        createApiKeyRequest: {
          name,
        },
        token,
      })
    );
    // gotoNextPhase();
  };

  useEffect(() => {
    if (apiKey && apiKey.created) {
      toast.success(
        `${apiKey.key} creation completed! Copying to clipboard...`
      );
      navigator.clipboard.writeText(apiKey.key!);
      gotoNextPhase();
    }
  }, [apiKey]);

  return (
    <>
      <div className="col-span-full flex flex-col justify-center @5xl:col-span-5">
        <PhasesBreadcrumb
          className="@7xl:me-10"
          heading="API Configuration"
          description="Configure which api key to use when exporting leads with Cleanlist."
        />
      </div>
      <div className="col-span-full flex items-center justify-center @5xl:col-span-7">
        <form
          id={`rhf-${phase.toString()}`}
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex-grow rounded-lg bg-white p-5 @4xl:p-7 dark:bg-gray-0"
        >
          <Input
            label="API Key"
            labelClassName="font-semibold text-gray-900"
            defaultValue="Personal"
            ref={nameRef}
            {...registerName}
            error={errors.name?.message}
          />
        </form>
      </div>
    </>
  );
};
