/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import {
  MODE,
  setThemeMode,
  useAppDispatch,
  useAppSelector,
} from "@cleanlist-ai/portal/store";
import { useEffect } from "react";
import { useMedia } from "react-use";

type ThemeProps = {};

export const Theme = ({}: ThemeProps) => {
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.layout);
  const mediaPreference = useMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (mediaPreference) {
      dispatch(setThemeMode(MODE.DARK));
    } else {
      dispatch(setThemeMode(MODE.LIGHT));
      document.documentElement.classList.remove("dark");
    }
  }, [mediaPreference]);

  useEffect(() => {
    if (mode === MODE.DARK) {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, [mode]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
