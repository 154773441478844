import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./auth";
import { cleanerReducer } from "./cleaner";
import { crmReducer } from "./crm";
import { layoutReducer } from "./layout";
import { leadsReducer } from "./leads";
import { userReducer } from "./user";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  blacklist: ["cleaner", "prospects", "leads"],
};

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  crm: crmReducer,
  layout: layoutReducer,
  cleaner: cleanerReducer,
  leads: leadsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
  // .prepend(routerMiddleware),
  //   .concat(usersApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// top-level state
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
