import { filterRecords } from "@cleanlist-ai/portal/utils";
import { useMemo, useState } from "react";

export function useTableColumn<T extends Record<string, any>>(
  columnsData: T[]
) {
  const [checkedColumns, setCheckedColumns] = useState(
    columnsData.map((column) => column.dataIndex)
  );

  const visibleColumns = useMemo(
    () => filterRecords(columnsData, checkedColumns),
    [columnsData, checkedColumns]
  );

  return { visibleColumns, checkedColumns, setCheckedColumns };
}
