import { usePhases } from "@cleanlist-ai/portal/store";
import Image from "next/image";
import { SubmitHandler, useForm } from "react-hook-form";
import OnboardingPng from "../../assets/onboarding.png";
import { PhasesBreadcrumb } from "./phases-breadcrumb.component";

export const PhaseOne = () => {
  const { phase, gotoNextPhase } = usePhases();
  const { handleSubmit } = useForm();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFormSubmit: SubmitHandler<{}> = () => {
    gotoNextPhase();
  };

  return (
    <>
      <div className="col-span-full flex flex-col justify-center @4xl:col-span-5">
        <PhasesBreadcrumb
          descriptionClassName="@7xl:me-10"
          heading="Onboarding for Cleanlist"
          description="The Cleanlist team is here to help you get started. We'll guide you through the process of configuring your account."
        />
      </div>

      <form
        id={`rhf-${phase.toString()}`}
        onSubmit={handleSubmit(onFormSubmit)}
        className="col-span-full grid aspect-[4/3] gap-4 @3xl:grid-cols-12 @4xl:col-span-7 @5xl:gap-5 @7xl:gap-8"
      >
        <Image
          src={OnboardingPng}
          alt="onboarding"
          priority
          className="mt-auto rounded-lg object-cover object-left-top @3xl:col-span-4 @3xl:h-96 @6xl:h-5/6"
        />
        <Image
          src={OnboardingPng}
          alt="onboarding"
          priority
          className="my-auto hidden rounded-lg object-cover @3xl:col-span-4 @3xl:block @3xl:h-96 @6xl:h-5/6"
        />
        <Image
          src={OnboardingPng}
          alt="onboarding"
          priority
          className="mb-auto hidden rounded-lg object-cover object-right-bottom @3xl:col-span-4 @3xl:block @3xl:h-96 @6xl:h-5/6"
        />
      </form>
    </>
  );
};
