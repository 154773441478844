import { Lead } from "@cleanlist-ai/cleanse-api-client-lib";

export enum LeadsErrorCodesEnum {
  FETCH_LEADS_FAILED = "FETCH_LEADS_FAILED",
  FETCH_LATEST_EXTRACTED_LEADS_FAILED = "FETCH_LATEST_EXTRACTED_LEADS_FAILED",
}

export interface ILeadsState {
  readonly leads: Lead[];
  readonly lastExtractedLeads: Lead[];
  readonly loading: boolean;
  readonly error: {
    code: LeadsErrorCodesEnum;
    message: string;
  } | null;
}
