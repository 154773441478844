import { cn } from "@cleanlist-ai/portal/utils";
import { Avatar, AvatarProps, Text } from "rizzui";

type AvatarCardProps = {
  src: string;
  name: string;
  className?: string;
  description?: string;
  avatarProps?: AvatarProps;
};
export const AvatarCard = ({
  name,
  src,
  avatarProps,
  className,
  description,
}: AvatarCardProps) => {
  return (
    <figure className={cn("flex items-center gap-3", className)}>
      <Avatar name={name} src={src} {...avatarProps} />
      <figcaption className="grid gap-0.5">
        <Text className="font-lato text-sm font-medium text-gray-900 dark:text-gray-700">
          {name}
        </Text>
        {description && (
          <Text className="text-[13px] text-gray-500">{description}</Text>
        )}
      </figcaption>
    </figure>
  );
};
