import { cn } from "@cleanlist-ai/portal/utils";
import { ReactNode } from "react";
import { Text } from "rizzui";

const metricCardClasses = {
  base: "border border-gray-200 bg-gray-0 p-5 dark:bg-gray-50 lg:p-6",
  rounded: {
    sm: "rounded-sm",
    DEFAULT: "rounded-lg",
    lg: "rounded-xl",
    xl: "rounded-2xl",
  },
};

type MetricsCardProps = {
  heading: string;
  metric: ReactNode;
  icon?: ReactNode;
  iconClassName?: string;
  contentClassName?: string;
  chart?: ReactNode;
  info?: ReactNode;
  rounded?: keyof typeof metricCardClasses.rounded;
  titleClassName?: string;
  metricClassName?: string;
  chartClassName?: string;
  className?: string;
  children: ReactNode;
};

export const MetricsCard = ({
  heading,
  metric,
  icon,
  chart,
  info,
  rounded = "DEFAULT",
  className,
  iconClassName,
  contentClassName,
  titleClassName: headingClassName,
  metricClassName,
  chartClassName,
  children,
}: MetricsCardProps) => {
  return (
    <div
      className={cn(
        metricCardClasses.base,
        metricCardClasses.rounded[rounded],
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {icon ? (
            <div
              className={cn(
                "flex h-11 w-11 items-center justify-center rounded-lg bg-gray-100 lg:h-12 lg:w-12",
                iconClassName
              )}
            >
              {icon}
            </div>
          ) : null}

          <div className={cn(icon && "ps-3", contentClassName)}>
            <Text className={cn("mb-0.5 text-gray-500", headingClassName)}>
              {heading}
            </Text>
            <Text
              className={cn(
                "font-lato text-lg font-semibold text-gray-900 dark:text-gray-700 2xl:xl:text-xl",
                metricClassName
              )}
            >
              {metric}
            </Text>

            {info ? info : null}
          </div>
        </div>

        {chart ? (
          <div className={cn("h-12 w-20", chartClassName)}>{chart}</div>
        ) : null}
      </div>

      {children}
    </div>
  );
};
