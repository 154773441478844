import { CleanProspectsRequest } from "@cleanlist-ai/cleanse-api-client-lib";
import { cleanerApi } from "./config";

const cleanProspects = (webtoken: string, payload: CleanProspectsRequest) => {
  return cleanerApi.cleanProspects(payload, {
    headers: {
      Authorization: `Bearer ${webtoken}`,
    },
  });
};

const fetchCleaningOptions = () => {
  return cleanerApi.getProspectCleaningOptions();
};

export const cleanerProvider = {
  cleanProspects,
  fetchCleaningOptions,
};
