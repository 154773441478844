export const routes = {
  dashboard: "/app",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    forgotPassword: "/auth/forgot-password",
    onboarding: "/auth/onboarding",
  },
  app: {
    prospect: "/app/prospect",
    leads: "/app/leads",
    companies: "/app/companies",
    lists: "/app/lists",
    cleaner: "/app/cleaner",
    preferences: "/app/preferences",
  },
  extractor: {
    details: (id: string) => `/extractor/${id}`,
    edit: (id: string) => `/extractor/${id}/edit`,
  },
  external: {
    website: "https://www.cleanlist.ai/",
    docs: "http://docs.cleanlist.ai/",
    linkedin: "https://www.linkedin.com/company/cleanlist-ai/",
  },
};
