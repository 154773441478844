import { cn } from "@cleanlist-ai/portal/utils";
import { ReactNode } from "react";
import { Text, Title } from "rizzui";

type BannerProps = {
  heading: ReactNode;
  description?: string;
  media?: ReactNode;
  contentClassName?: string;
  className?: string;
  children?: ReactNode;
};

export const Banner = ({
  heading,
  className,
  contentClassName,
  description,
  media,
  children,
}: BannerProps) => {
  return (
    <div
      className={cn(
        "relative flex items-center justify-between rounded-lg bg-gray-100/60 p-5 dark:bg-gray-100 sm:p-6 lg:p-7",
        className
      )}
    >
      <div className={cn(contentClassName)}>
        <Title as="h1" className="mb-2 text-2xl sm:mb-3 md:text-3xl">
          {heading}
        </Title>
        {description && (
          <Text className="mb-5 text-sm leading-[1.6] text-gray-700 sm:mb-6 sm:text-base md:mb-8 lg:mb-10">
            {description}
          </Text>
        )}
        {children}
      </div>
      {media && <div>{media}</div>}
    </div>
  );
};
