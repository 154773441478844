import {
  APIKey,
  APIKeyLimits,
  Credits,
} from "@cleanlist-ai/cleanse-api-client-lib";

export enum UserStatusEnum {
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}

export enum UserErrorCodesEnum {
  FETCH_USER_METADATA_FAILED = "FETCH_USER_METADATA_FAILED",
  FETCH_USER_API_KEYS_FAILED = "FETCH_USER_API_KEYS_FAILED",
  CREATE_USER_API_KEY_FAILED = "CREATE_USER_API_KEY_FAILED",
  FETCH_CREDS_PACKAGE_FAILED = "FETCH_CREDS_PACKAGE_FAILED",
  FETCH_USER_API_KEY_LIMITS_FAILED = "FETCH_USER_API_KEY_LIMITS_FAILED",
  VERIFY_RECAPTCHA_FAILED = "VERIFY_RECAPTCHA_FAILED",
}

export interface IUserState {
  // Comes from API
  readonly apiKey: APIKey | null;
  readonly apiKeys: APIKey[] | null;
  readonly limits?: APIKeyLimits | null;
  readonly creditLimits: Credits | null;
  readonly loading: boolean;
  readonly error: {
    code: UserErrorCodesEnum;
    message: string;
  } | null;
}
