import { ReactNode } from "react";
import {
  PiBroom,
  PiChartBarDuotone,
  PiListChecks,
  PiPackageDuotone,
  PiPhoneCall,
  PiStorefront,
} from "react-icons/pi";
import { routes } from "../../routes";

export type MenuEntry = {
  name: string;
  href?: string;
  link?: string;
  icon?: ReactNode;
  badge?: string;
  childrenMenu?: MenuEntry[];
  keybind?: {
    modifier: string;
    key: string;
  };
};

export const dropdownMenuEntries: MenuEntry[] = [
  {
    name: "Preferences",
    link: routes.app.preferences,
  },
  {
    name: "Website",
    href: routes.external.website,
  },
  {
    name: "Docs",
    href: routes.external.docs,
  },
];

export const menuEntries: MenuEntry[] = [
  {
    name: "Overview",
  },
  {
    name: "Dashboard",
    href: routes.dashboard,
    icon: <PiChartBarDuotone />,
    keybind: {
      modifier: "alt",
      key: "1",
    },
  },
  {
    name: "Prospecting",
  },
  {
    name: "Lists",
    href: routes.app.lists,
    icon: <PiListChecks />,
    keybind: {
      modifier: "alt",
      key: "2",
    },
  },
  {
    name: "Leads",
    href: routes.app.leads,
    icon: <PiPhoneCall />,
    keybind: {
      modifier: "alt",
      key: "3",
    },
  },
  {
    name: "Companies",
    href: routes.app.companies,
    // href: routes.file.dashboard,
    icon: <PiStorefront />,
    keybind: {
      modifier: "alt",
      key: "4",
    },
  },
  {
    name: "Prospect",
    href: routes.app.prospect,
    icon: <PiPackageDuotone />,
    keybind: {
      modifier: "alt",
      key: "5",
    },
  },
  {
    name: "Cleaning",
  },
  {
    name: "Cleaner",
    href: routes.app.cleaner,
    icon: <PiBroom />,
    keybind: {
      modifier: "alt",
      key: "6",
    },
  },
];
