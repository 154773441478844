import { PHASE, usePhases } from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import { useUser } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { MouseEvent } from "react";
import { PiArrowUpLight, PiCheck } from "react-icons/pi";
import { Button } from "rizzui";
import { routes } from "../routes";

interface FooterProps {
  formId?: number;
  className?: string;
  isLoading?: boolean;
}

function buttonLabel(phase: PHASE) {
  if (phase === PHASE.FIVE) {
    return (
      <>
        Confirm <PiCheck />
      </>
    );
  }
  if (phase === PHASE.SIX) {
    return "Navigate to portal";
  }
  return (
    <>
      Next <PiArrowUpLight className="rotate-90" />
    </>
  );
}

export const OnboardingFooter = ({ className, isLoading }: FooterProps) => {
  const router = useRouter();
  const { phase, gotoNextPhase, gotoPrevPhase } = usePhases();
  const { user } = useUser();

  const buttonProps = () => {
    if (phase === PHASE.ONE) {
      return {
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          gotoNextPhase();
        },
      };
    }
    if (phase === PHASE.SIX) {
      return {
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          user?.update({
            unsafeMetadata: {
              completedOnboarding: true,
            },
          });
          router.push(routes.dashboard);
        },
      };
    }
    return { form: `rhf-${phase?.toString()}` };
  };

  return (
    <footer
      className={cn(
        "fixed bottom-0 left-0 right-0 flex items-center justify-between gap-3 px-4 py-5 lg:px-8 4xl:px-10",
        className
      )}
    >
      {phase >= 1 && phase < 6 && (
        <Button
          rounded="pill"
          variant="outline"
          onClick={gotoPrevPhase}
          className="gap-1 text-white backdrop-blur-lg hover:enabled:border-white dark:border-gray-800 dark:hover:enabled:border-white"
        >
          <PiArrowUpLight className="-rotate-90" />
          Back
        </Button>
      )}
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        rounded="pill"
        {...buttonProps()}
        type="submit"
        className="ml-auto gap-1 bg-gray-900/[.35] backdrop-blur-lg dark:bg-gray-0/[.35] dark:text-white dark:active:enabled:bg-gray-0/75"
      >
        {buttonLabel(phase)}
      </Button>
    </footer>
  );
};
