import { useAppSelector, usePhases } from "@cleanlist-ai/portal/store";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { AdvancedRadio, RadioGroup } from "rizzui";
import * as yup from "yup";
import { PhasesBreadcrumb } from "./phases-breadcrumb.component";

const crmSchema = yup.object({
  crm: yup.string().default(""),
});

type CRMFormValues = yup.InferType<typeof crmSchema>;

export const PhaseThree = () => {
  const { phase, gotoNextPhase } = usePhases();
  const { options } = useAppSelector((state) => state.crm);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<CRMFormValues>({
    resolver: yupResolver(crmSchema),
    defaultValues: {
      crm: "",
    },
  });

  useEffect(() => {
    if (errors.crm) {
      toast.error(errors.crm.message as string);
    }
  }, [errors]);

  const onFormSubmit: SubmitHandler<CRMFormValues> = ({ crm }) => {
    console.log(crm);
    gotoNextPhase();
  };

  return (
    <>
      <div className="col-span-full flex flex-col justify-center @5xl:col-span-5">
        <PhasesBreadcrumb
          className="@7xl:me-10"
          heading="CRM Configuration"
          description="Configure which CRM to use when exporting leads with Cleanlist."
        />
      </div>
      <div className="col-span-full flex items-center justify-center @5xl:col-span-7">
        <form
          id={`rhf-${phase.toString()}`}
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex-grow rounded-lg bg-white p-5 @4xl:p-7 dark:bg-gray-0"
        >
          <Controller
            name="crm"
            control={control}
            render={({ field: { value, onChange } }) => (
              <RadioGroup
                value={value}
                setValue={onChange}
                className="col-span-full grid gap-4 @4xl:gap-6"
              >
                {options.map((crm) => (
                  <AdvancedRadio
                    key={crm.type}
                    value={crm.type}
                    onClick={() => {
                      setValue("crm", crm.type!);
                      window.open(crm.url, "_blank");
                    }}
                    className=" [&_.rizzui-advanced-radio]:flex [&_.rizzui-advanced-radio]:justify-between [&_.rizzui-advanced-radio]:gap-7 [&_.rizzui-advanced-radio]:px-6 [&_.rizzui-advanced-radio]:py-6"
                    inputClassName="[&~span]:border-0 [&~span]:ring-1 [&~span]:ring-gray-200 [&~span:hover]:ring-primary [&:checked~span:hover]:ring-primary [&:checked~span]:border-1 [&:checked~.rizzui-advanced-radio]:ring-2 [&~span_.icon]:opacity-0 [&:checked~span_.icon]:opacity-100"
                  >
                    <article>
                      <h4 className="text-sm font-semibold @5xl:text-base">
                        {crm.name}
                      </h4>
                      <p>{crm.type}</p>
                    </article>
                    <span className="relative flex h-9 w-9 flex-shrink-0 items-center justify-center overflow-hidden">
                      <Image
                        src={crm.logoUrl!}
                        width={100}
                        height={100}
                        priority
                        alt="crm logo"
                      />
                    </span>
                  </AdvancedRadio>
                ))}
              </RadioGroup>
            )}
          />
        </form>
      </div>
    </>
  );
};
