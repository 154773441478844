import { CRMOption } from "@cleanlist-ai/cleanse-api-client-lib";

export enum CRMErrorCodesEnum {
  FETCH_CRM_OPTIONS_FAILED = "FETCH_CRM_OPTIONS_FAILED",
}

export interface ICRMState {
  readonly options: CRMOption[];
  readonly loading: boolean;
  readonly error: {
    code: CRMErrorCodesEnum;
    message: string;
  } | null;
}
